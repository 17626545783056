import React, {Component} from "react";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ResultBox from "./ResultBox";
import {PDFDownloadLink} from '@react-pdf/renderer'
import {PdfDocument} from "./Document";
import {
    OBJECT_TRAIT_ADAPTABILITY,
    OBJECT_TRAIT_COMMUNICATION,
    OBJECT_TRAIT_CREATIVITY,
    OBJECT_TRAIT_CRITICAL_THINKING,
    OBJECT_TRAIT_DISCIPLINE,
    OBJECT_TRAIT_EMPATHY,
    OBJECT_TRAIT_FLEXIBILITY,
    OBJECT_TRAIT_GRIT,
    OBJECT_TRAIT_INNOVATION,
    OBJECT_TRAIT_LEADERSHIP,
    OBJECT_TRAIT_MANAGEMENT,
    OBJECT_TRAIT_METICULOUS,
    OBJECT_TRAIT_PLANNING_ORGANIZATION,
    OBJECT_TRAIT_PROBLEM_SOLVING,
    OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE,
    CATEGORY_OBJECT_CIVIL_SERVICES,
    CATEGORY_OBJECT_COMMERCE,
    CATEGORY_OBJECT_DEFENCE_POLICE_FORCES,
    CATEGORY_OBJECT_DESIGN_FINE_ARTS,
    CATEGORY_OBJECT_EDUCATION,
    CATEGORY_OBJECT_ENGINEER,
    CATEGORY_OBJECT_HUMANITITES,
    CATEGORY_OBJECT_LAW,
    CATEGORY_OBJECT_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
    CATEGORY_OBJECT_MEDICAL_SCIENCE,
    CATEGORY_OBJECT_MISCELLANEOUS,
    CATEGORY_OBJECT_PURE_SCIENCES,
    CATEGORY_OBJECT_SOCIAL_WORK,
    CATEGORY_OBJECT_TRAVEL_TOURISM_HOSPITILITY

} from "../quizService";

const TRAIT_CIVIL_SERVICES = [OBJECT_TRAIT_DISCIPLINE, OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_LEADERSHIP, OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE, OBJECT_TRAIT_PROBLEM_SOLVING]
const TRAIT_COMMERCE = [OBJECT_TRAIT_METICULOUS, OBJECT_TRAIT_MANAGEMENT, OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE, OBJECT_TRAIT_PLANNING_ORGANIZATION, OBJECT_TRAIT_PROBLEM_SOLVING]
const TRAIT_DEFENCE_POLICE_FORCES = [OBJECT_TRAIT_DISCIPLINE, OBJECT_TRAIT_PLANNING_ORGANIZATION, OBJECT_TRAIT_LEADERSHIP, OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE, OBJECT_TRAIT_ADAPTABILITY]
const TRAIT_DESIGN_FINE_ARTS = [OBJECT_TRAIT_CREATIVITY, OBJECT_TRAIT_INNOVATION, OBJECT_TRAIT_METICULOUS, OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_FLEXIBILITY]
const TRAIT_EDUCATION = [OBJECT_TRAIT_ADAPTABILITY, OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_PLANNING_ORGANIZATION, OBJECT_TRAIT_MANAGEMENT, OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE]
const TRAIT_ENGINEER = [OBJECT_TRAIT_CRITICAL_THINKING, OBJECT_TRAIT_PROBLEM_SOLVING, OBJECT_TRAIT_PLANNING_ORGANIZATION, OBJECT_TRAIT_MANAGEMENT, OBJECT_TRAIT_INNOVATION]
const TRAIT_HUMANITITES = [OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_PROBLEM_SOLVING, OBJECT_TRAIT_CREATIVITY, OBJECT_TRAIT_CRITICAL_THINKING, OBJECT_TRAIT_METICULOUS]
const TRAIT_LAW = [OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_CRITICAL_THINKING, OBJECT_TRAIT_PROBLEM_SOLVING, OBJECT_TRAIT_METICULOUS, OBJECT_TRAIT_PLANNING_ORGANIZATION]
const TRAIT_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS = [OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_FLEXIBILITY, OBJECT_TRAIT_CREATIVITY, OBJECT_TRAIT_GRIT, OBJECT_TRAIT_PLANNING_ORGANIZATION]
const TRAIT_MEDICAL_SCIENCE = [OBJECT_TRAIT_CRITICAL_THINKING, OBJECT_TRAIT_METICULOUS, OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_EMPATHY, OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE]
const TRAIT_MISCELLANEOUS = [OBJECT_TRAIT_GRIT, OBJECT_TRAIT_FLEXIBILITY, OBJECT_TRAIT_CREATIVITY, OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_PLANNING_ORGANIZATION]
const TRAIT_PURE_SCIENCES = [OBJECT_TRAIT_CRITICAL_THINKING, OBJECT_TRAIT_PROBLEM_SOLVING, OBJECT_TRAIT_INNOVATION, OBJECT_TRAIT_GRIT, OBJECT_TRAIT_PLANNING_ORGANIZATION]
const TRAIT_SOCIAL_WORK = [OBJECT_TRAIT_EMPATHY, OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_PROBLEM_SOLVING, OBJECT_TRAIT_PLANNING_ORGANIZATION, OBJECT_TRAIT_ADAPTABILITY]
const TRAIT_TRAVEL_TOURISM_HOSPITILITY = [OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE, OBJECT_TRAIT_COMMUNICATION, OBJECT_TRAIT_EMPATHY, OBJECT_TRAIT_ADAPTABILITY, OBJECT_TRAIT_PROBLEM_SOLVING]

class Result extends Component {

    constructor(props) {
        super(props);

        this.calculateSums = this.calculateSums.bind(this);
        this.getRecommendedCategories = this.getRecommendedCategories.bind(this);
        this.getValueObject = this.getValueObject.bind(this);
        this.getRecommdedCategory = this.getRecommdedCategory.bind(this);

        this.sumAnswers = [];
        this.sumAnswerDirectory = {};
        this.recommendedCategories = [];
        this.valuesObjects = []
        this.recommendedCategoryOne = {}
        this.recommendedCategoryTwo = {}
        this.recommendedCategoryThree = {}
        this.recommendedCategoryFour = {}
        this.details = {}
    }

    state = {
        isPostSuccess: true,
        userDetails: {},
        valueObject: [],
        recommendationCategories: [],
        categoryOne: {},
        categoryTwo: {},
        categoryThree: {},
        categoryFour: {}
    }


    postQuiz = () => {
        let respo =
            {
                "responses": {
                    "civil_services": this.props.civil_services,
                    "commerce": this.props.commerce,
                    "defence_police_force": this.props.defence_police_force,
                    "design_fine_arts": this.props.design_fine_arts,
                    "education": this.props.education,
                    "engineer": this.props.engineer,
                    "humanities": this.props.humanities,
                    "law": this.props.law,
                    "mass_media": this.props.mass_media,
                    "medical_science": this.props.medical_science,
                    "miscellaneous": this.props.miscellaneous,
                    "pure_sciences": this.props.pure_sciences,
                    "social_work": this.props.social_work,
                    "travel_tourism": this.props.travel_tourism,

                }
            }

        var token = this.props.user_details.token
        axios.post('https://probano.herokuapp.com/user_responses/', JSON.stringify(respo),
            {
                headers: {
                    Authorization: "Token " + token,
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch(error => {
                console.log('the error has occured: ' + error)
            })
    }

    calculateSums = () => {
        let civil_services = typeof this.props.civil_services !== "undefined" ? this.props.civil_services.reduce((a, b) => a + b, 0) : 1000
        let commerce = typeof this.props.commerce !== "undefined" ? this.props.commerce.reduce((a, b) => a + b, 0) : 1000
        let defence_police_force = typeof this.props.defence_police_force !== "undefined" ? this.props.defence_police_force.reduce((a, b) => a + b, 0) : 1000
        let design_fine_arts = typeof this.props.design_fine_arts !== "undefined" ? this.props.design_fine_arts.reduce((a, b) => a + b, 0) : 1000
        let education = typeof this.props.education !== "undefined" ? this.props.education.reduce((a, b) => a + b, 0) : 1000
        let engineer = typeof this.props.engineer !== "undefined" ? this.props.engineer.reduce((a, b) => a + b, 0) : 1000
        let humanities = typeof this.props.humanities !== "undefined" ? this.props.humanities.reduce((a, b) => a + b, 0) : 1000
        let law = typeof this.props.law !== "undefined" ? this.props.law.reduce((a, b) => a + b, 0) : 1000
        let mass_media = typeof this.props.mass_media !== "undefined" ? this.props.mass_media.reduce((a, b) => a + b, 0) : 1000
        let medical_science = typeof this.props.medical_science !== "undefined" ? this.props.medical_science.reduce((a, b) => a + b, 0) : 1000
        let miscellaneous = typeof this.props.miscellaneous !== "undefined" ? this.props.miscellaneous.reduce((a, b) => a + b, 0) : 1000
        let pure_sciences = typeof this.props.pure_sciences !== "undefined" ? this.props.pure_sciences.reduce((a, b) => a + b, 0) : 1000
        let social_work = typeof this.props.social_work !== "undefined" ? this.props.social_work.reduce((a, b) => a + b, 0) : 1000
        let travel_tourism = typeof this.props.travel_tourism !== "undefined" ? this.props.travel_tourism.reduce((a, b) => a + b, 0) : 1000
        let array_sum = [civil_services, commerce, defence_police_force, design_fine_arts, education
            , engineer, humanities, law, mass_media, medical_science, miscellaneous, pure_sciences, social_work, travel_tourism]

        let dictionary = {}
        dictionary['civil_services'] = civil_services
        dictionary['commerce'] = commerce
        dictionary['defence_police_force'] = defence_police_force
        dictionary['design_fine_arts'] = design_fine_arts
        dictionary['education'] = education
        dictionary['engineer'] = engineer
        dictionary['humanities'] = humanities
        dictionary['law'] = law
        dictionary['mass_media'] = mass_media
        dictionary['medical_science'] = medical_science
        dictionary['miscellaneous'] = miscellaneous
        dictionary['pure_sciences'] = pure_sciences
        dictionary['social_work'] = social_work
        dictionary['travel_tourism'] = travel_tourism

        this.sumAnswers = array_sum.sort();
        this.sumAnswerDirectory = dictionary;
    }

    getRecommendedCategories = (dict) => {
        // Create items array
        var items = Object.keys(dict).map(function (key) {
            return [key, dict[key]];
        });

        // Sort the array based on the second element
        items.sort(function (first, second) {
            return first[1] - second[1];
        });

        // Create a new array with only the first 5 items
        // console.log(items.slice(0, 5));
        this.recommendedCategories = items

        this.setState({
            recommendationCategories: items.slice(0, 5)
        })
    }

    getValueObject = (a) => {
        switch (a) {
            case 'civil_services':
                return TRAIT_CIVIL_SERVICES
            case 'commerce':
                return TRAIT_COMMERCE
            case 'defence_police_force':
                return TRAIT_DEFENCE_POLICE_FORCES
            case 'design_fine_arts':
                return TRAIT_DESIGN_FINE_ARTS
            case 'education':
                return TRAIT_EDUCATION
            case 'engineer':
                return TRAIT_ENGINEER
            case 'humanities':
                return TRAIT_HUMANITITES
            case 'law':
                return TRAIT_LAW
            case 'mass_media':
                return TRAIT_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS
            case 'medical_science':
                return TRAIT_MEDICAL_SCIENCE
            case 'miscellaneous':
                return TRAIT_MISCELLANEOUS
            case 'pure_sciences':
                return TRAIT_PURE_SCIENCES
            case 'social_work':
                return TRAIT_SOCIAL_WORK
            case 'travel_tourism':
                return TRAIT_TRAVEL_TOURISM_HOSPITILITY

        }
    }

    getRecommdedCategory = (a) => {
        switch (a) {
            case 'civil_services':
                return CATEGORY_OBJECT_CIVIL_SERVICES
            case 'commerce':
                return CATEGORY_OBJECT_COMMERCE
            case 'defence_police_force':
                return CATEGORY_OBJECT_DEFENCE_POLICE_FORCES
            case 'design_fine_arts':
                return CATEGORY_OBJECT_DESIGN_FINE_ARTS
            case 'education':
                return CATEGORY_OBJECT_EDUCATION
            case 'engineer':
                return CATEGORY_OBJECT_ENGINEER
            case 'humanities':
                return CATEGORY_OBJECT_HUMANITITES
            case 'law':
                return CATEGORY_OBJECT_LAW
            case 'mass_media':
                return CATEGORY_OBJECT_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS
            case 'medical_science':
                return CATEGORY_OBJECT_MEDICAL_SCIENCE
            case 'miscellaneous':
                return CATEGORY_OBJECT_MISCELLANEOUS
            case 'pure_sciences':
                return CATEGORY_OBJECT_PURE_SCIENCES
            case 'social_work':
                return CATEGORY_OBJECT_SOCIAL_WORK
            case 'travel_tourism':
                return CATEGORY_OBJECT_TRAVEL_TOURISM_HOSPITILITY
        }
    }

    getResult = () => {
        this.getRecommendedCategories(this.sumAnswerDirectory)
        this.valuesObjects = this.getValueObject(this.recommendedCategories[0][0])
        this.recommendedCategoryOne = this.getRecommdedCategory(this.recommendedCategories[0][0])
        this.recommendedCategoryTwo = this.getRecommdedCategory(this.recommendedCategories[1][0])
        this.recommendedCategoryThree = this.getRecommdedCategory(this.recommendedCategories[2][0])
        this.recommendedCategoryFour = this.getRecommdedCategory(this.recommendedCategories[3][0])
        // console.log('recommendedCategories', this.recommendedCategories)
        // console.log('valueObjects', this.valuesObjects)
        this.details = this.props.user_details
        this.setState({
            categoryOne: this.recommendedCategoryOne,
            categoryTwo: this.recommendedCategoryTwo,
            categoryThree: this.recommendedCategoryThree,
            categoryFour: this.recommendedCategoryFour,
            valueObject: this.valuesObjects,
            userDetails: this.details
        })
    }

    handleClickQuestion = () => {
    }

    componentDidMount() {
        this.calculateSums()
        this.postQuiz()
        this.getResult()
    }

    render() {
        return (
            <div id={"result_body"}>
                <div id="result" className="title">RESULT</div>
                <div className="ruleBox">
                    <div className="textResult">{this.props.user_details.user_name}</div>
                    <div className="textResult">{this.props.user_details.user_school}</div>
                    <div className="textResult">{this.props.user_details.user_standard}</div>
                </div>
                {(typeof this.state.valueObject !== 'undefined' &&
                    Object.keys(this.state.categoryOne).length !== 0 &&
                    Object.keys(this.state.categoryTwo).length !== 0 &&
                    Object.keys(this.state.categoryThree).length !== 0 &&
                    Object.keys(this.state.categoryFour).length !== 0) &&
                <div>
                    <div className="textResult">Your top four Professions are</div>
                    <ResultBox
                        option_image={this.state.categoryOne.image}
                        option_title={this.state.categoryOne.title}
                        option_description={this.state.categoryOne.description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <ResultBox
                        option_image={this.state.categoryTwo.image}
                        option_title={this.state.categoryTwo.title}
                        option_description={this.state.categoryTwo.description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <ResultBox
                        option_image={this.state.categoryThree.image}
                        option_title={this.state.categoryThree.title}
                        option_description={this.state.categoryThree.description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <ResultBox
                        option_image={this.state.categoryFour.image}
                        option_title={this.state.categoryFour.title}
                        option_description={this.state.categoryFour.description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <div className="textResult">Your Personality Traits are</div>
                    <ResultBox
                        option_image={this.state.valueObject[0].image}
                        option_title={this.state.valueObject[0].title}
                        option_description={this.state.valueObject[0].description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <ResultBox
                        option_image={this.state.valueObject[1].image}
                        option_title={this.state.valueObject[1].title}
                        option_description={this.state.valueObject[1].description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <ResultBox
                        option_image={this.state.valueObject[2].image}
                        option_title={this.state.valueObject[2].title}
                        option_description={this.state.valueObject[2].description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <ResultBox
                        option_image={this.state.valueObject[3].image}
                        option_title={this.state.valueObject[3].title}
                        option_description={this.state.valueObject[3].description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <ResultBox
                        option_image={this.state.valueObject[4].image}
                        option_title={this.state.valueObject[4].title}
                        option_description={this.state.valueObject[4].description}
                        priority={0}
                        option_key={114}
                        fun={this.handleClickQuestion}/>
                    <div className="score-board" align="center">
                        <PDFDownloadLink document={<PdfDocument
                            user_details={this.state.userDetails}
                            recommendation_one={this.state.categoryOne}
                            recommendation_two={this.state.categoryTwo}
                            recommendation_three={this.state.categoryThree}
                            recommendation_four={this.state.categoryFour}
                            trait_one={this.state.valueObject[0]}
                            trait_two={this.state.valueObject[1]}
                            trait_three={this.state.valueObject[2]}
                            trait_four={this.state.valueObject[3]}
                            trait_five={this.state.valueObject[4]}
                        />} fileName="report.pdf"
                                         style={{
                                             textDecoration: "none",
                                             padding: "10px",
                                             color: "#4a4a4a",
                                             backgroundColor: "#f2f2f2",
                                             border: "1px solid #4a4a4a",
                                             width: "100%",
                                         }}>Download PDF
                        </PDFDownloadLink>
                    </div>
                </div>
                }
            </div>

        );
    }

}

export default Result;
