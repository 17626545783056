import React, {Component} from "react";

class QuestionBox extends Component {

    handleClick = (e) => {
        this.props.fun(this.props.option_key)
    }

    render() {
        return (
            <div className="questionBox">
                <div className="questionImg"><img
                    src={typeof this.props.option_image !== 'undefined' ? require('../illustrations/' + this.props.option_image) : null}
                    alt="CFA"
                    width="120"
                    height="120"/></div>
                <div className="questionContainer" onClick={this.handleClick}>
                    <div className="question">{this.props.option_title}</div>
                    <div className="questionDetails">{this.props.option_description}</div>
                    {this.props.priority !== 0 && <div className="badge" >{this.props.priority}</div>}
                </div>
            </div>
        );
    }
}

export default QuestionBox;
