import React, {Component} from "react";
import axios from "axios";

class Rules extends Component {

    state = {
        textButton: "Start Quiz"
    }

    description_english = "There will be four options for each 35 question."
    rule_one_english = "Choose your most liked option first."
    rule_two_english = "Then choose your second most liked option."
    rule_three_english = "Then choose your third most liked option."
    rule_four_english = "Then choose your least liked option."
    rule_five_english = "Submit each question."
    description_hindi = "प्रत्येक प्रश्न के लिए चार विकल्प होंगे"
    rule_one_hindi = "सबसे पहले अपना सबसे पसंदीदा विकल्प चुनें।"
    rule_two_hindi = "इसके बाद अपना दूसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प चुनें।"
    rule_three_hindi = "इसके बाद अपना तीसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प चुनें।"
    rule_four_hindi = "फिर अपना सबसे कम पसंद किया गया विकल्प चुनें।"
    rule_five_hindi = "प्रत्येक प्रश्न सबमिट करें।"


    startQuiz = () => {
        this.setState({
            textButton: "Loading..."
        })
        var url = window.location.href
        var token = url.split("/", 4)
        var apiUrl = 'https://probano.herokuapp.com/welcome/' + token[token.length - 1]
        axios.get(apiUrl)
            .then(res => {
                var token = res.data['token']
                var user_details = {
                    "token": token,
                    "user_name": res.data['user_details'].name,
                    "user_standard": res.data['user_details'].class,
                    "user_school": res.data['user_details'].school
                }
                this.props.detailsUser(user_details)
                if (res.data['user_responses'] != null) {
                    var responses_user = {
                        "user_responses_civil_services": res.data['user_responses'].civil_services,
                        "user_responses_commerce": res.data['user_responses'].commerce,
                        "user_responses_defence_police_force": res.data['user_responses'].defence_police_force,
                        "user_responses_design_fine_arts": res.data['user_responses'].design_fine_arts,
                        "user_responses_education": res.data['user_responses'].education,
                        "user_responses_engineer": res.data['user_responses'].engineer,
                        "user_responses_humanities": res.data['user_responses'].humanities,
                        "user_responses_law": res.data['user_responses'].law,
                        "user_responses_mass_media": res.data['user_responses'].mass_media,
                        "user_responses_medical_science": res.data['user_responses'].medical_science,
                        "user_responses_miscellaneous": res.data['user_responses'].miscellaneous,
                        "user_responses_pure_sciences": res.data['user_responses'].pure_sciences,
                        "user_responses_social_work": res.data['user_responses'].social_work,
                        "user_responses_travel_tourism": res.data['user_responses'].travel_tourism
                    }
                    this.props.responses(responses_user)
                    this.props.startFun(2)
                } else {
                    this.props.startFun(1)
                }
            })
            .catch(reason => {
                this.props.startFun(3)
            })
    }

    render() {
        return (
            <div>
                <div className="title">RULES</div>
                <div className="ruleBox">
                    <div className="textRules">{this.description_english}</div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_one_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_two_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_three_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_four_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_five_english}</div>
                    </div>
                    <div className="textRules">{this.description_hindi}</div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_one_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_two_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_three_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_four_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_five_hindi}</div>
                    </div>
                    <button className="coloredNextBtn" onClick={this.startQuiz}>{this.state.textButton}</button>
                </div>
            </div>
        )
            ;
    }

}

export default Rules;
