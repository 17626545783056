export const CATEGORY_ID_CIVIL_SERVICES = 1
export const CATEGORY_ID_COMMERCE = 2
export const CATEGORY_ID_DEFENCE_POLICE_FORCES = 3
export const CATEGORY_ID_DESIGN_FINE_ARTS = 4
export const CATEGORY_ID_EDUCATION = 5
export const CATEGORY_ID_ENGINEER = 6
export const CATEGORY_ID_HUMANITITES = 7
export const CATEGORY_ID_LAW = 8
export const CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS = 9
export const CATEGORY_ID_MEDICAL_SCIENCE = 10
export const CATEGORY_ID_MISCELLANEOUS = 11
export const CATEGORY_ID_PURE_SCIENCES = 12
export const CATEGORY_ID_SOCIAL_WORK = 13
export const CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY = 14

export const CATEGORY_TITLE_CIVIL_SERVICES = "Civil Services"
export const CATEGORY_TITLE_COMMERCE = "Commerce"
export const CATEGORY_TITLE_DEFENCE_POLICE_FORCES = "Defence & Police Forces"
export const CATEGORY_TITLE_DESIGN_FINE_ARTS = "Design & Fine Arts"
export const CATEGORY_TITLE_EDUCATION = "Education"
export const CATEGORY_TITLE_ENGINEER = "Engineer"
export const CATEGORY_TITLE_HUMANITITES = "Humanities"
export const CATEGORY_TITLE_LAW = "Law"
export const CATEGORY_TITLE_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS = "Mass Media,Journalism, File Production & Performing Arts"
export const CATEGORY_TITLE_MEDICAL_SCIENCE = "Medical Science"
export const CATEGORY_TITLE_MISCELLANEOUS = "Miscellaneous"
export const CATEGORY_TITLE_PURE_SCIENCES = "Pure Sciences"
export const CATEGORY_TITLE_SOCIAL_WORK = "Social Work"
export const CATEGORY_TITLE_TRAVEL_TOURISM_HOSPITILITY = "Travel, Tourism & Hospitality"

export const DESCRIPTION_TITLE_CIVIL_SERVICES = "Civil Service officers deliver public services and support the government of the day to develop and implement its policies. "
export const DESCRIPTION_TITLE_COMMERCE = "Commerce is another word for trade or business, and can mean simply the buying and selling of goods and services. This sector primarily involves looking into specific aspects of running a commercial enterprise."
export const DESCRIPTION_TITLE_DEFENCE_POLICE_FORCES = "Defense Force(s)  is the title of the armed forces of certain countries. They are the first line of defense when it comes to protecting the citizens of the country."
export const DESCRIPTION_TITLE_DESIGN_FINE_ARTS = "Designing is creating a plan or specification for the construction of an object or system or for the implementation of an activity creating a prototype. Fine Arts is that whose products are to be appreciated primarily or solely for their imaginative, aesthetic, or intellectual content."
export const DESCRIPTION_TITLE_EDUCATION = "In this field the primary task is to give intellectual, moral, and social instruction to a student, typically at a school or university."
export const DESCRIPTION_TITLE_ENGINEER = "Engineering uses the principles of science and mathematics to develop economical solutions to technical problems. "
export const DESCRIPTION_TITLE_HUMANITITES = "Humanities are academic disciplines that study aspects of human society and culture."
export const DESCRIPTION_TITLE_LAW = "A lawyer or attorney is a person who practices law, as an advocate, attorney, barrister, canonist, canon lawyer, civil law notary, counselor, solicitor, legal executive, or public servant preparing, interpreting and applying law."
export const DESCRIPTION_TITLE_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS = "Journalism primarily involves news reporting, be it for newspapers, magazines or television, while mass communication is a broader field encompassing films, online media, etc. Performing Arts are those performed in front of an audience, such as drama, music, and dance."
export const DESCRIPTION_TITLE_MEDICAL_SCIENCE = "The science of dealing with the maintenance of health and the prevention and treatment of disease."
export const DESCRIPTION_TITLE_MISCELLANEOUS = "These are offbeat career where you work with your passion, to engage your creativity and interest in creating something new. "
export const DESCRIPTION_TITLE_PURE_SCIENCES = "This field is based on deductions from demonstrated truths, such as mathematics or logic, or studied without regard to practical applications."
export const DESCRIPTION_TITLE_SOCIAL_WORK = "Social work is an academic discipline and practice-based profession that concerns itself with individuals, families, groups, and communities in an effort to enhance social functioning and overall well-being."
export const DESCRIPTION_TITLE_TRAVEL_TOURISM_HOSPITILITY = "Travel & Tourism deals with attractions, activities, and events, while Hospitality focuses on providing people great accommodation, travel, as well as other facilities like restaurants or sports centers."

export const PATH_CIVIL_SERVICES_IAS = 'Civil Services/IAS.svg'
export const PATH_CIVIL_SERVICES_IFS = 'Civil Services/IFS.svg'
export const PATH_CIVIL_SERVICES_IAAS = 'Civil Services/Indian Audit and Accounts Service (IAAS).svg'
export const PATH_CIVIL_SERVICES_ICAS = 'Civil Services/Indian Civil Accounts Service (ICAS).svg'
export const PATH_CIVIL_SERVICES_ICLS = 'Civil Services/Indian Corporate Law Service (ICLS).svg'
export const PATH_CIVIL_SERVICES_IIS = 'Civil Services/Indian Information Service (IIS).svg'
export const PATH_CIVIL_SERVICES_IPOS = 'Civil Services/Indian Postal Service (IPoS).svg'
export const PATH_CIVIL_SERVICES_IRAS = 'Civil Services/Indian Railway Accounts Service (IRAS).svg'
export const PATH_CIVIL_SERVICES_IRS_IT = 'Civil Services/Indian Revenue Service (IRS-IT).svg'
export const PATH_CIVIL_SERVICES_IPS = 'Civil Services/IPS.svg'
export const PATH_COMMERCE_ACCOUNTANT = 'Commerce/Accountant.svg'
export const PATH_COMMERCE_BANK_OFFICER = 'Commerce/Bank Officer .svg'
export const PATH_COMMERCE_CFA = 'Commerce/CFA.svg'
export const PATH_COMMERCE_ECONOMIST = 'Commerce/Economist.svg'
export const PATH_COMMERCE_ENTREPRENEUR = 'Commerce/Entrepreneur .svg'
export const PATH_COMMERCE_FOREIGN_TRADE = 'Commerce/Foreign Trade.svg'
export const PATH_COMMERCE_IB_SB = 'Commerce/Investment Banking and Stock Brokering.svg'
export const PATH_COMMERCE_MARKETING_MANAGER = 'Commerce/Marketing Manager.svg'
export const PATH_COMMERCE_PORTFOLIO_MANAGER = 'Commerce/Portfolio Manager.svg'
export const PATH_COMMERCE_SALES_MANAGER = 'Commerce/Sales Manager.svg'
export const PATH_DEFENCE_POLICE_FORCES_AIR_FORCE = 'Defence & Police Forces/Air Force.svg'
export const PATH_DEFENCE_POLICE_FORCES_ARMY = 'Defence & Police Forces/Army.svg'
export const PATH_DEFENCE_POLICE_FORCES_ASSAM_RIFFLE = 'Defence & Police Forces/Assam Riffle.svg'
export const PATH_DEFENCE_POLICE_FORCES_BSF = 'Defence & Police Forces/BSF.svg'
export const PATH_DEFENCE_POLICE_FORCES_CISF = 'Defence & Police Forces/CISF.svg'
export const PATH_DEFENCE_POLICE_FORCES_CRPF = 'Defence & Police Forces/CRPF.svg'
export const PATH_DEFENCE_POLICE_FORCES_ITBP = 'Defence & Police Forces/ITBP.svg'
export const PATH_DEFENCE_POLICE_FORCES_NAVY = 'Defence & Police Forces/Navy.svg'
export const PATH_DEFENCE_POLICE_FORCES_POLICE_FORCE = 'Defence & Police Forces/Police Force.svg'
export const PATH_DEFENCE_POLICE_FORCES_SSB = 'Defence & Police Forces/SSB.svg'
export const PATH_DESIGN_FINE_ARTS_ANIMATION_DESIGNER = 'Design and Fine Arts/Animation Designer.svg'
export const PATH_DESIGN_FINE_ARTS_AUTOMOBILE_DESIGNER = 'Design and Fine Arts/Automobile Designer.svg'
export const PATH_DESIGN_FINE_ARTS_FASHION_DESIGNER = 'Design and Fine Arts/Fashion Designer.svg'
export const PATH_DESIGN_FINE_ARTS_GRAPHIC_DESIGNER = 'Design and Fine Arts/Graphic Designer.svg'
export const PATH_DESIGN_FINE_ARTS_INTERIOR_DESIGNER = 'Design and Fine Arts/Interior Designer.svg'
export const PATH_DESIGN_FINE_ARTS_PAINTER = 'Design and Fine Arts/Painter.svg'
export const PATH_DESIGN_FINE_ARTS_PRODUCT_DESIGNER = 'Design and Fine Arts/Product Designer.svg'
export const PATH_DESIGN_FINE_ARTS_SCULPTOR = 'Design and Fine Arts/Sculptor.svg'
export const PATH_DESIGN_FINE_ARTS_UX_DESIGNER = 'Design and Fine Arts/UX Designer.svg'
export const PATH_DESIGN_FINE_ARTS_WEB_DESIGNER = 'Design and Fine Arts/Website Designer.svg'
export const PATH_EDUCATION_ADMINISTRATOR = 'Education/Administrator .svg'
export const PATH_EDUCATION_COUNSELLOR = 'Education/Counsellor.svg'
export const PATH_EDUCATION_LAB_TECHNICIAN = 'Education/Lab Technician.svg'
export const PATH_EDUCATION_LIBRARIAN = 'Education/Librarian.svg'
export const PATH_EDUCATION_PROFESSOR = 'Education/Professor.svg'
export const PATH_EDUCATION_PSYCHOLOGIST = 'Education/Psychologist.svg'
export const PATH_EDUCATION_SPECIAL_EDUCATION = 'Education/Special Education.svg'
export const PATH_EDUCATION_SPORTS_COACH = 'Education/Sports Coach.svg'
export const PATH_EDUCATION_TEACHER = 'Education/Teacher.svg'
export const PATH_EDUCATION_TUTOR = 'Education/Tutor.svg'
export const PATH_ENGINEER_AEROSPACE = 'Engineer/Aerospace .svg'
export const PATH_ENGINEER_ARCHITECHTURE = 'Engineer/Architechture.svg'
export const PATH_ENGINEER_BIOTECHNOLOGY = 'Engineer/Biotechnology.svg'
export const PATH_ENGINEER_CIVIL = 'Engineer/Civil.svg'
export const PATH_ENGINEER_COMPUTER_SCIENCES = 'Engineer/Computer Sciences.svg'
export const PATH_ENGINEER_ELECTRICAL = 'Engineer/Electrical.svg'
export const PATH_ENGINEER_ELECTRONICS = 'Engineer/Electronics.svg'
export const PATH_ENGINEER_MECHANICAL = 'Engineer/Mechanical.svg'
export const PATH_ENGINEER_METALLURGY = 'Engineer/Metallurgy.svg'
export const PATH_ENGINEER_MINING = 'Engineer/Mining .svg'
export const PATH_HUMANITIES_ARCHEOLOGIST = 'Humanities/Archeologist.svg'
export const PATH_HUMANITIES_GEOGRAPHY = 'Humanities/Geography.svg'
export const PATH_HUMANITIES_HISTORIAN = 'Humanities/Historian.svg'
export const PATH_HUMANITIES_HR_MANAGER = 'Humanities/Human Resource Manager.svg'
export const PATH_HUMANITIES_LANGUAGE_EXPERT = 'Humanities/Language Expert.svg'
export const PATH_HUMANITIES_LITERATURE_EXPERT = 'Humanities/Literature Expert.svg'
export const PATH_HUMANITIES_PHILOSOPHER = 'Humanities/philosopher.svg'
export const PATH_HUMANITIES_POLITICAL_SCIENCES = 'Humanities/Political Sciences.svg'
export const PATH_HUMANITIES_PUBLIC_POLICY_MAKER = 'Humanities/Public Policy Maker.svg'
export const PATH_HUMANITIES_SOCIOLOGIST = 'Humanities/Sociologist.svg'
export const PATH_LAW_CIVIL_LAWYER = 'Law/Civil Lawyer.svg'
export const PATH_LAW_CORPORATE_LAWYER = 'Law/Corporate Lawyer.svg'
export const PATH_LAW_CRIMINAL_LAWYER = 'Law/Criminal Lawyer.svg'
export const PATH_LAW_DATA_PROTECTION_LAWYER = 'Law/Data Protection Lawyer.svg'
export const PATH_LAW_FAMILY_LAWYER = 'Law/Family Lawyer.svg'
export const PATH_LAW_GENERAL_PRACTICE_LAWYER = 'Law/General Practise Lawyer.svg'
export const PATH_LAW_IMMIGRATION_LAWYER = 'Law/Immigration Lawyer.svg'
export const PATH_LAW_IPR_LAWYER = 'Law/IPR Lawyer.svg'
export const PATH_LAW_LABOUR_LAWYER = 'Law/Labour Law.svg'
export const PATH_LAW_TAXATION_LAWYER = 'Law/Taxation Lawyer.svg'
export const PATH_MASS_MEDIA_ACTOR = 'Mass Media, Journalism, Film and Performing Arts/Actor.svg'
export const PATH_MASS_MEDIA_BLOGGER = 'Mass Media, Journalism, Film and Performing Arts/Blogger.svg'
export const PATH_MASS_MEDIA_CHOREOGRAPHER = 'Mass Media, Journalism, Film and Performing Arts/Choreographer.svg'
export const PATH_MASS_MEDIA_COMEDIAN = 'Mass Media, Journalism, Film and Performing Arts/comedian.svg'
export const PATH_MASS_MEDIA_DIRECTOR = 'Mass Media, Journalism, Film and Performing Arts/Director.svg'
export const PATH_MASS_MEDIA_JOURNALIST = 'Mass Media, Journalism, Film and Performing Arts/Journalist.svg'
export const PATH_MASS_MEDIA_PRODUCER = 'Mass Media, Journalism, Film and Performing Arts/Producer.svg'
export const PATH_MASS_MEDIA_RADIO_JOCKEY = 'Mass Media, Journalism, Film and Performing Arts/Radio Jockey.svg'
export const PATH_MASS_MEDIA_SCRIPT_WRITER = 'Mass Media, Journalism, Film and Performing Arts/Script Writer.svg'
export const PATH_MASS_MEDIA_SINGER = 'Mass Media, Journalism, Film and Performing Arts/Singer.svg'
export const PATH_MEDICAL_SCIENCE_AYURVEDIC = 'Medical Science/Ayurvedic.svg'
export const PATH_MEDICAL_SCIENCE_DENTIST = 'Medical Science/Dentist.svg'
export const PATH_MEDICAL_SCIENCE_GENERAL_MEDICINE = 'Medical Science/General Medicine.svg'
export const PATH_MEDICAL_SCIENCE_HOMEOPATHY = 'Medical Science/Homeopathy.svg'
export const PATH_MEDICAL_SCIENCE_NEUROLOGY = 'Medical Science/Neurology.svg'
export const PATH_MEDICAL_SCIENCE_NURSE = 'Medical Science/Nurse.svg'
export const PATH_MEDICAL_SCIENCE_PARAMEDICAL = 'Medical Science/Paramedical.svg'
export const PATH_MEDICAL_SCIENCE_PHARMACIST = 'Medical Science/Pharmacist.svg'
export const PATH_MEDICAL_SCIENCE_PHYSIOTHERAPIST = 'Medical Science/Physiotherapist.svg'
export const PATH_MEDICAL_SCIENCE_VETERINARY = 'Medical Science/Veterinary.svg'
export const PATH_MISCELLANEOUS_ATHLETE = 'Miscellaneous/Athlete.svg'
export const PATH_MISCELLANEOUS_FITNESS = 'Miscellaneous/Fitness.svg'
export const PATH_MISCELLANEOUS_FOOD_TESTER = 'Miscellaneous/Food Tester.svg'
export const PATH_MISCELLANEOUS_HAIR_STYLIST = 'Miscellaneous/Hair Stylist.svg'
export const PATH_MISCELLANEOUS_MAKE_UP_ARTIST = 'Miscellaneous/Make-up Artist.svg'
export const PATH_MISCELLANEOUS_NUTRITIONIST = 'Miscellaneous/Nutritionist.svg'
export const PATH_MISCELLANEOUS_PHOTOGRAPHY = 'Miscellaneous/Photography.svg'
export const PATH_MISCELLANEOUS_POLITICIAN = 'Miscellaneous/Politician.svg'
export const PATH_MISCELLANEOUS_SPORTS_PLAYER = 'Miscellaneous/Sports Player.svg'
export const PATH_MISCELLANEOUS_TATTOO_ARTIST = 'Miscellaneous/Tattoo Artist.svg'
export const PATH_PURE_SCIENCES_AGRICULTURIST = 'Pure Sciences/Agriculatist.svg'
export const PATH_PURE_SCIENCES_ASTRONOMIST = 'Pure Sciences/Astronomist.svg'
export const PATH_PURE_SCIENCES_BIOCHEMIST = 'Pure Sciences/Biochemist.svg'
export const PATH_PURE_SCIENCES_BOTANIST = 'Pure Sciences/Botanist.svg'
export const PATH_PURE_SCIENCES_CHEMIST = 'Pure Sciences/Chemist.svg'
export const PATH_PURE_SCIENCES_GEOLOGIST = 'Pure Sciences/Geologist.svg'
export const PATH_PURE_SCIENCES_MATHEMATICIAN = 'Pure Sciences/Mathematician.svg'
export const PATH_PURE_SCIENCES_OCEANOLOGIST = 'Pure Sciences/Oceanologist.svg'
export const PATH_PURE_SCIENCES_PHYSICIST = 'Pure Sciences/Physicist .svg'
export const PATH_PURE_SCIENCES_ZOOLOGIST = 'Pure Sciences/Zoologist.svg'
export const PATH_SOCIAL_WORK_CFSS_SOCIAL_WORKER = 'Social Work/Child, Family and School Social Worker.svg'
export const PATH_SOCIAL_WORK_COMMUNITY_SOCIAL_WORKER = 'Social Work/Community Social Worker.svg'
export const PATH_SOCIAL_WORK_HEALTHCARE_SOCIAL_WORKER = 'Social Work/Healthcare Social Worker.svg'
export const PATH_SOCIAL_WORK_H_PC_SOCIAL_WORKER = 'Social Work/Hospice and Palliative Care Social Worker.svg'
export const PATH_SOCIAL_WORK_HSW_SOCIAL_WORKER = 'Social Work/Hygiene, Sanitation and Water Social Work .svg'
export const PATH_SOCIAL_WORK_LS_SOCIAL_WORKER = 'Social Work/Labour Support Social Worker.svg'
export const PATH_SOCIAL_WORK_MV_CARE_SOCIAL_WORKER = 'Social Work/Military & Veteran Care Social Worker.svg'
export const PATH_SOCIAL_WORK_PSYCHIATRIC_SOCIAL_WORKER = 'Social Work/Psychiatric Social Worker.svg'
export const PATH_SOCIAL_WORK_SOCIAL_POLICY_ANALYST = 'Social Work/social policy analyst.svg'
export const PATH_SOCIAL_WORK_SA_SOCIAL_WORKER = 'Social Work/Substance Abuse Social Worker.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_AIR_HOSTESS_STEWARD = 'Travel, Tourism and Hospitality/Air Hostess Steward.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_BARTENDER = 'Travel, Tourism and Hospitality/Bartender.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_CHEF = 'Travel, Tourism and Hospitality/Chef.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_COMMERCIAL_PILOT = 'Travel, Tourism and Hospitality/Commercial Pilot.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_EVENT_MANAGER = 'Travel, Tourism and Hospitality/Event Manager.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_FRONT_OFFICE_RECEPTIONIST = 'Travel, Tourism and Hospitality/Front Office Receptionist.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_HOUSEKEEPING = 'Travel, Tourism and Hospitality/Housekeeping.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_OPERATIONS_MANAGER = 'Travel, Tourism and Hospitality/Operations Manager.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_PLANNER = 'Travel, Tourism and Hospitality/Travel Planner.svg'
export const PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_VLOGGER = 'Travel, Tourism and Hospitality/Travel Vlogger.svg'

export const DESCRIPTION_CIVIL_SERVICES_IAS = "A civil servant who handles the administration and daily proceedings of the government, including the formulation and implementation of policy in consultation with the minister-in-charge of a specific ministry or department."
export const DESCRIPTION_CIVIL_SERVICES_IFS = "IFS Officer deals with the external affairs of the country, which includes diplomacy, trade, and cultural relations."
export const DESCRIPTION_CIVIL_SERVICES_IAAS = "The officers of the Indian Audit and Accounts Service serve in an audit managerial capacity, in the Indian Audit and Accounts Department."
export const DESCRIPTION_CIVIL_SERVICES_ICAS = "The primary task of an ICAS officer is to strengthen governance through excellence in public financial management."
export const DESCRIPTION_CIVIL_SERVICES_ICLS = "Someone who deals in regulations and privacies of corporate sector laws."
export const DESCRIPTION_CIVIL_SERVICES_IIS = "Indian Information Service officers are the media managers of the Government of India dealing with various forms of mass communication."
export const DESCRIPTION_CIVIL_SERVICES_IPOS = "IPoS officers are tasked with looking after the overall administration of the postal division where they are posted."
export const DESCRIPTION_CIVIL_SERVICES_IRAS = "The Officers of this Service are responsible for the Accounts and Finance Management of the Indian Railways."
export const DESCRIPTION_CIVIL_SERVICES_IRS_IT = "IRS officers are concerned with the collection, administration and policy formation of direct (Income, Corporate, Wealth, FBT etc.) and indirect (Central Excise, Service Tax and even Customs Duty) taxes."
export const DESCRIPTION_CIVIL_SERVICES_IPS = "IPS officers have to make sure that there is no physical or economic harm to properties or people in their allotted area."
export const DESCRIPTION_COMMERCE_ACCOUNTANT = "The primary task of accountants, which extends to all the others, is to prepare and examine financial records. They make sure that records are accurate and that taxes are paid properly and on time."
export const DESCRIPTION_COMMERCE_BANK_OFFICER = "A banking officer oversees many aspects of a retail banking environment. General duties include monitoring the operations for correct accounting procedures, approving loan packages, and developing relationships with banking customers."
export const DESCRIPTION_COMMERCE_CFA = "CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities."
export const DESCRIPTION_COMMERCE_ECONOMIST = "Economists conduct research, collect and analyze data, monitor economic trends, and develop forecasts."
export const DESCRIPTION_COMMERCE_ENTREPRENEUR = "Someone who solves a public problem in an innovative way and creates and runs an enterprise to do the same."
export const DESCRIPTION_COMMERCE_FOREIGN_TRADE = "A foreign exchange trader looks at the various factors that influence local economies and rates of exchange, then takes advantage of any misvaluations of currencies by buying and selling in different foreign exchange markets."
export const DESCRIPTION_COMMERCE_IB_SB = "Investment bankers help their clients raise money in capital markets by issuing debt or selling equity in the companies. Other job duties include assisting clients with mergers and acquisitions (M&As) and advising them on unique investment opportunities such as derivatives."
export const DESCRIPTION_COMMERCE_MARKETING_MANAGER = "A marketing manager is responsible for managing the promotion and positioning of a brand or the products and services that a company sells. Typically marketing managers are employed to attract more customers to buy from the company and to raise brand awareness through the creation of marketing campaigns."
export const DESCRIPTION_COMMERCE_PORTFOLIO_MANAGER = "A portfolio manager is a person or group of people responsible for investing a mutual, exchange-traded or closed-end fund's assets, implementing its investment strategy and managing day-to-day portfolio trading. "
export const DESCRIPTION_COMMERCE_SALES_MANAGER = "Someone who is primarily tasked with increasing the sales of a particular company"
export const DESCRIPTION_DEFENCE_POLICE_FORCES_AIR_FORCE = "The Air Force focuses on flying, but only a small fraction of personnel actually fly. Most airmen and airwomen work on flight support missions, handling base affairs, protecting bases, constructing new airstrips, guarding missile sites, even doing rescues."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_ARMY = "Soldiers do many things, from shooting enemies, to digging defensive trenches. They are used to defend their country, or attack another country's army."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_ASSAM_RIFFLE = "Assam Rifles (AR) is the oldest paramilitary force in India whose primary role is to protect part of India 's Northeastern border."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_BSF = "BSF was created as a Central government-controlled security force to guard all of India's borders, thus bringing greater cohesion in border security."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_CISF = "The CISF provides security cover to 300 industrial units, government infrastructure projects and facilities and establishments located all over India."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_CRPF = "The CRPF's primary role lies in assisting the State/Union Territories in police operations to maintain law and order and counter insurgency."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_ITBP = "ITBP is a multi-dimensional force which primarily has 5 functions: Vigil on the northern borders, detection and prevention of border violations, and promotion of the sense of security among the local populace. Check illegal immigration and trans-border smuggling."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_NAVY = "The Navy maintains, trains, and equips combat-ready naval forces capable of winning wars, deterring aggression and maintaining the freedom of the seas."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_POLICE_FORCE = "The police are a constituted body of persons empowered by a state, with the aim to enforce the law, to ensure the safety, health and possessions of citizens, and to prevent crime and civil disorder."
export const DESCRIPTION_DEFENCE_POLICE_FORCES_SSB = "Sashastra Seema Bal (SSB) is India's primary border patrol organization with its border with Nepal and Bhutan. It is a Central Armed Police Force."
export const DESCRIPTION_DESIGN_FINE_ARTS_ANIMATION_DESIGNER = 'An animation designer utilizes computer technology to create images and effects for movies, television, websites and video games.'
export const DESCRIPTION_DESIGN_FINE_ARTS_AUTOMOBILE_DESIGNER = 'Automotive designers, also known as automotive stylists, are specialized industrial designers who combine their technical knowledge of mechanics, production, and materials with artistic talent to improve the style, appearance, and ergonomic and aerodynamic design of automobiles.'
export const DESCRIPTION_DESIGN_FINE_ARTS_FASHION_DESIGNER = 'They help create the billions of dresses, suits, shoes, and other clothing and accessories purchased every year by consumers.'
export const DESCRIPTION_DESIGN_FINE_ARTS_GRAPHIC_DESIGNER = 'Graphic designers create visual concepts, using computer software or by hand, to communicate ideas that inspire, inform, and captivate consumers.'
export const DESCRIPTION_DESIGN_FINE_ARTS_INTERIOR_DESIGNER = 'Someone who makes indoor spaces functional, safe, and beautiful by determining space requirements and selecting essential and decorative items, such as colors, lighting, and materials.'
export const DESCRIPTION_DESIGN_FINE_ARTS_PAINTER = 'A painter applies paint and other decorative finishes to interior and exterior surfaces of buildings and other structures./Painter.svg'
export const DESCRIPTION_DESIGN_FINE_ARTS_PRODUCT_DESIGNER = 'They design most things we use in our day-to-day lives, from chairs and cutlery to clocks and computers, as well as specialist products like medical, electronics or telecommunications equipment.'
export const DESCRIPTION_DESIGN_FINE_ARTS_SCULPTOR = 'A sculptor is a highly creative fine artist who develops ideas for sculptures or statues, and makes them come to life in three-dimensional form by joining or molding materials together./Sculptor.svg'
export const DESCRIPTION_DESIGN_FINE_ARTS_UX_DESIGNER = 'Someone who creates products that provide meaningful and relevant experiences to users for a particular product./UX Designer.svg'
export const DESCRIPTION_DESIGN_FINE_ARTS_WEB_DESIGNER = 'Someone who constructs the overall look and feel of a website, using images and programming languages like HTML, CSS, and JavaScript./Website Designer.svg'
export const DESCRIPTION_EDUCATION_ADMINISTRATOR = 'They direct programming, hire and supervise staff, manage budgets, and make decisions that affect the academic community.'
export const DESCRIPTION_EDUCATION_COUNSELLOR = 'A counsellor is a trained, objective professional with whom you can build a healing and trusting relationship.'
export const DESCRIPTION_EDUCATION_LAB_TECHNICIAN = 'Lab technicians might collect samples, study and perform tests on body fluids, teeth, chemical compounds, biological specimens, or other fields of science.svg'
export const DESCRIPTION_EDUCATION_LIBRARIAN = "Librarians manage staff and develop and direct information programs and systems for the public and ensure that information is organized in a manner that meets users'needs."
export const DESCRIPTION_EDUCATION_PROFESSOR = 'Professors often conduct original research and commonly teach undergraduate, professional, or postgraduate courses in their fields of expertise.'
export const DESCRIPTION_EDUCATION_PSYCHOLOGIST = 'A psychologist is someone who studies mental processes and human behaviour by observing, interpreting, and recording how people relate to one another and the environment.'
export const DESCRIPTION_EDUCATION_SPECIAL_EDUCATION = 'A teacher who primarily deals with the education of handicapped children.svg'
export const DESCRIPTION_EDUCATION_SPORTS_COACH = 'Someone who trains students in basic physical fitness and enables them to use it in a particular sport.svg'
export const DESCRIPTION_EDUCATION_TEACHER = 'Teachers create lesson plans and teach those plans to the entire class, individually to students or in small groups.'
export const DESCRIPTION_EDUCATION_TUTOR = 'Someone who typically teaches a small group of people on a personal capacity on his/her area of expertise.'
export const DESCRIPTION_ENGINEER_AEROSPACE = 'Someone whose primary task is to design and develop aircrafts, spacecrafts, satellites and missiles.'
export const DESCRIPTION_ENGINEER_ARCHITECHTURE = 'A professional who is qualified to design and provide advice - both aesthetic and technical - on built objects in our public and private landscapes.'
export const DESCRIPTION_ENGINEER_BIOTECHNOLOGY = 'Someone who uses applied biology and chemical engineering principles that involves the use of living things in technology, medicine, and other useful applications.'
export const DESCRIPTION_ENGINEER_CIVIL = 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.'
export const DESCRIPTION_ENGINEER_COMPUTER_SCIENCES = 'Someone who uses his knowledge in computer systems both in hardware and software to design and operate computers.'
export const DESCRIPTION_ENGINEER_ELECTRICAL = 'Someone who is concerned with the study of devices that use the concepts of electricity, electronics and electromagnetism.'
export const DESCRIPTION_ENGINEER_ELECTRONICS = 'Someone who uses his knowledge of electrical components to design, develop and test electronic circuits and devices.'
export const DESCRIPTION_ENGINEER_MECHANICAL = 'Someone who combines his knowledge in engineering physics and mathematics principles with materials science to design, analyze, manufacture, and maintain mechanical systems.'
export const DESCRIPTION_ENGINEER_METALLURGY = 'As a Metallurgical Engineer, you work with a variety of metals to design new products, refine the collection process, and create different blends of metal to suit specific needs.'
export const DESCRIPTION_ENGINEER_MINING = 'Someone who deals with extraction of minerals from underneath, above or on the ground.'
export const DESCRIPTION_HUMANITIES_ARCHEOLOGIST = "Archaeologists study past human activity by excavating, dating and interpreting objects and sites of historical interest."
export const DESCRIPTION_HUMANITIES_GEOGRAPHY = "Geographers use maps and global positioning systems in their work. Geographers study the Earth and the distribution of its land, features, and inhabitants."
export const DESCRIPTION_HUMANITIES_HISTORIAN = "Historians are academics and researchers who study events of the past."
export const DESCRIPTION_HUMANITIES_HR_MANAGER = "Human resources managers plan, coordinate, and direct the administrative functions of an organization. They oversee the recruiting, interviewing, and hiring of new staff; consult with top executives on strategic planning; and serve as a link between an organization's management and its employees."
export const DESCRIPTION_HUMANITIES_LANGUAGE_EXPERT = "Language specialists translate human verbal communication in real-time or written text from one language to another. Of course, you would also have to include language educators, language analysts (FBI), or people who specialize in sign language."
export const DESCRIPTION_HUMANITIES_LITERATURE_EXPERT = "A literature major involves reading and analyzing works of literature. This means discussing texts and understanding their historical, cultural and literary significance."
export const DESCRIPTION_HUMANITIES_PHILOSOPHER = "A Philosopher makes his/her philosophical ideas known to others, which means either speaking to audiences or recording them in written (or some other) format in order to disperse them."
export const DESCRIPTION_HUMANITIES_POLITICAL_SCIENCES = "Political scientists often present their findings. Political scientists study the origin, development, and operation of political systems."
export const DESCRIPTION_HUMANITIES_PUBLIC_POLICY_MAKER = "The role of the policy maker is act as a funnel to gather information through consultation and research and to reduce and extract from the information, a policy or a set of policies which serve to promote what is the preferred course of action."
export const DESCRIPTION_HUMANITIES_SOCIOLOGIST = "Sociologists study human behavior, interaction, and organization. They observe the activity of social, religious, political, and economic groups, organizations, and institutions."
export const DESCRIPTION_LAW_CIVIL_LAWYER = 'A civil attorney, commonly known as a litigator, is a lawyer hired by a client to pursue or defend a civil lawsuit in a court of law.'
export const DESCRIPTION_LAW_CORPORATE_LAWYER = "Corporate Lawyers are experts in commercial law. They are tasked with ensuring a company's transactions comply with corporate laws and regulations."
export const DESCRIPTION_LAW_CRIMINAL_LAWYER = 'Criminal lawyers represent defendants facing criminal charges in state, federal and appellate courts.'
export const DESCRIPTION_LAW_DATA_PROTECTION_LAWYER = 'Data privacy lawyers focus on complex and evolving laws and regulations that govern everything that happens in connection with data transmitted and stored on computers, including consumer protection laws, privacy laws, and e-discovery issues.'
export const DESCRIPTION_LAW_FAMILY_LAWYER = 'Most family law practices focus on representing clients in a divorce and the issues related to divorce such as the division of marital property, child custody and support, and alimony.'
export const DESCRIPTION_LAW_GENERAL_PRACTICE_LAWYER = 'General practice attorneys generally prepare paperwork, meet with clients, and represent clients in court and do not specialize in a particular field.'
export const DESCRIPTION_LAW_IMMIGRATION_LAWYER = 'Immigration lawyers provide advice and guidance for matters such as visa applications, green cards, citizenship and naturalization, deportation issues, and employment for non-citizens.'
export const DESCRIPTION_LAW_IPR_LAWYER = 'A Lawyer who primarily deals with patenting and protection of intellectual property of an individual or organization.'
export const DESCRIPTION_LAW_LABOUR_LAWYER = 'Labor lawyers are those who regulate unions and collective action on the part of workers to bring about change in working conditions and pay.'
export const DESCRIPTION_LAW_TAXATION_LAWYER = 'A Lawyer that specializes in tax laws of a particular country and the norms and regulations that go along with it.'
export const DESCRIPTION_MASS_MEDIA_ACTOR = "Someone who acts by playing various characters in films, short-films, movies, advertisements etc."
export const DESCRIPTION_MASS_MEDIA_BLOGGER = "There are various types of bloggers. They write about everything ranging from their experiences with travel, tourism, food etc. social and political issues."
export const DESCRIPTION_MASS_MEDIA_CHOREOGRAPHER = "Is someone who uses his/her knowledge in dance and music to innovate and create dance performances."
export const DESCRIPTION_MASS_MEDIA_COMEDIAN = "Someone who uses his wit to humor audiences. They range from doing shows live known as stand-up comedy to performing in pre recorded comedy based tv shows and movies."
export const DESCRIPTION_MASS_MEDIA_DIRECTOR = "Someone in charge of the look and feel of a particular movie/short-film/advertisement. It is the director who decides how the final product will look on screen and is up to him/her to convey the script in an entertaining fashion to the audience."
export const DESCRIPTION_MASS_MEDIA_JOURNALIST = "They write and report news stories about everything, from social issues and politics to sports and entertainment."
export const DESCRIPTION_MASS_MEDIA_PRODUCER = "Someone who invests capital into the process of movie making. Primarily the producer is a businessman who tries to generate profit by investing in movies. He uses networking, communication and other skills to help the cast, production team and directors in successfully visualizing, creating and distributing movies or tv shows."
export const DESCRIPTION_MASS_MEDIA_RADIO_JOCKEY = "A Radio Jockey (aka RJ) is responsible for playing music, holding discussions with the listeners, and conducting interviews on radio."
export const DESCRIPTION_MASS_MEDIA_SCRIPT_WRITER = "Someone who comes up with the initial storyline for the production of a movie. Nowadays they are also involved in various other aspects like dialogue writing."
export const DESCRIPTION_MASS_MEDIA_SINGER = "Someone who sings professionally for events, shows, movies etc."
export const DESCRIPTION_MEDICAL_SCIENCE_AYURVEDIC = 'Someone who uses the science of Ayurveda to treat illnesses.'
export const DESCRIPTION_MEDICAL_SCIENCE_DENTIST = 'Doctors who specialize in oral health.'
export const DESCRIPTION_MEDICAL_SCIENCE_GENERAL_MEDICINE = 'A medical doctor who specializes in many diseases affecting the body, whose primary treatment does not involve surgery.'
export const DESCRIPTION_MEDICAL_SCIENCE_HOMEOPATHY = 'A doctor who uses his knowledge in the field of homeopathy which concerns itself with self-healing to cure illnesses.'
export const DESCRIPTION_MEDICAL_SCIENCE_NEUROLOGY = 'A medical doctor with specialized training in diagnosing, treating, and managing disorders of the brain and nervous system'
export const DESCRIPTION_MEDICAL_SCIENCE_NURSE = 'Someone whose duties include communicating between patients and doctors, caring for patients and administering medicine.'
export const DESCRIPTION_MEDICAL_SCIENCE_PARAMEDICAL = 'Paramedics and emergency medical technicians (EMTs) care for the sick or injured in emergency medical settings'
export const DESCRIPTION_MEDICAL_SCIENCE_PHARMACIST = 'Pharmacists, also known as dispensing chemists, are healthcare professionals who focus on the safe and effective use of medications.'
export const DESCRIPTION_MEDICAL_SCIENCE_PHYSIOTHERAPIST = 'Physiotherapists help people affected by injury, illness or disability through movement and exercise, manual therapy, education and advice.'
export const DESCRIPTION_MEDICAL_SCIENCE_VETERINARY = 'A doctor who specializes in treatment of pets and animals in general.'
export const DESCRIPTION_MISCELLANEOUS_ATHLETE = "Athletes and sports competitors play in organized and officiated sports teams nationally or internationally."
export const DESCRIPTION_MISCELLANEOUS_FITNESS = "Fitness trainers and instructors lead, instruct, and motivate individuals or groups in exercise activities, including cardiovascular exercises, strength training, and stretching."
export const DESCRIPTION_MISCELLANEOUS_FOOD_TESTER = "Performs standardized qualitative and quantitative tests to determine physical or chemical properties of food or beverage products, or to ensure compliance with company or government quality standards"
export const DESCRIPTION_MISCELLANEOUS_HAIR_STYLIST = "A hair stylist shampoos, cuts, colors, and styles hair. He or she may educate clients about hair and scalp care, remove unwanted hair, or dress a wig."
export const DESCRIPTION_MISCELLANEOUS_MAKE_UP_ARTIST = "Makeup Artists are beauty practitioners who offer general makeup services or work in prosthetic design."
export const DESCRIPTION_MISCELLANEOUS_NUTRITIONIST = "Nutritionists are experts in food and nutrition. They can help patients choose the right things to eat, help them plan menus, and advise them on the health effects of certain foods."
export const DESCRIPTION_MISCELLANEOUS_PHOTOGRAPHY = "Photographers are artists with the camera, using a blend of technical skills and an artistic eye to take pictures of people, places, landscapes, food, you name it."
export const DESCRIPTION_MISCELLANEOUS_POLITICIAN = "A politician is a person active in party politics, or a person holding or seeking an office in government. Politicians propose, support and create laws or policies that govern the land and, by extension, its people."
export const DESCRIPTION_MISCELLANEOUS_SPORTS_PLAYER = "A sports player primarily focuses on body fitness, endurance and skills in a particular sport to play professionally."
export const DESCRIPTION_MISCELLANEOUS_TATTOO_ARTIST = "A tattoo artist applies a permanent design directly to a client's body, injecting ink under a layer of skin."
export const DESCRIPTION_PURE_SCIENCES_AGRICULTURIST = "Someone concerned with the science or art or business of cultivating the soil. agriculturalist, cultivator, grower, raiser. farmer, granger, husbandman, sodbuster - a person who operates a farm. fruit grower - someone who grows fruit commercially."
export const DESCRIPTION_PURE_SCIENCES_ASTRONOMIST = "An astronomer is a scientist in the field of astronomy who focuses their studies on a specific question or field outside the scope of Earth."
export const DESCRIPTION_PURE_SCIENCES_BIOCHEMIST = "Biochemists and biophysicists study the chemical and physical principles of living things and of biological processes, such as cell development, growth, heredity, and disease."
export const DESCRIPTION_PURE_SCIENCES_BOTANIST = "Botanists study the biology of plants, fungi and other organisms, such as lichens and algae."
export const DESCRIPTION_PURE_SCIENCES_CHEMIST = "Chemists investigate the properties of matter at the level of atoms and molecules."
export const DESCRIPTION_PURE_SCIENCES_GEOLOGIST = "Geologists study Earth processes: Many processes such as landslides, earthquakes, floods, and volcanic eruptions can be hazardous to people."
export const DESCRIPTION_PURE_SCIENCES_MATHEMATICIAN = "Mathematicians address the relationships of quantities, magnitudes, and forms through the use of numbers and symbols."
export const DESCRIPTION_PURE_SCIENCES_OCEANOLOGIST = "Oceanography covers a wide range of topics, including marine life and ecosystems, ocean circulation, plate tectonics and the geology of the seafloor, and the chemical and physical properties of the ocean."
export const DESCRIPTION_PURE_SCIENCES_PHYSICIST = "Physicists often conduct, investigate, and conclude to solutions or theories in science. They also design scientific equipment"
export const DESCRIPTION_PURE_SCIENCES_ZOOLOGIST = "Most zoologists are employed by museums, zoos or research laboratories. A typical day for a zoologist consists of checking in with all the animals under his care and making sure that they have plenty of food and water."
export const DESCRIPTION_SOCIAL_WORK_CFSS_SOCIAL_WORKER = "They ensure that children, families or young people receive appropriate care, education and health and other basic needs."
export const DESCRIPTION_SOCIAL_WORK_COMMUNITY_SOCIAL_WORKER = "Community social workers help communities’ function. Some work directly with individuals, conducting needs assessments and making referrals to resources in the community."
export const DESCRIPTION_SOCIAL_WORK_HEALTHCARE_SOCIAL_WORKER = "A healthcare social worker is someone who helps people access services and education related to health problems."
export const DESCRIPTION_SOCIAL_WORK_H_PC_SOCIAL_WORKER = "Hospice and palliative care social work is a very challenging field, as the daily work involves helping patients and their families cope with severe illness and death."
export const DESCRIPTION_SOCIAL_WORK_HSW_SOCIAL_WORKER = "A social worker who deals with the overall sanitation facilities, water availability and quality, environmental hygiene etc. esp. for low income and rural areas."
export const DESCRIPTION_SOCIAL_WORK_LS_SOCIAL_WORKER = "They deal with a variety of aspects including legal help, healthcare, financial care etc of the low income working class."
export const DESCRIPTION_SOCIAL_WORK_MV_CARE_SOCIAL_WORKER = "This social worker primarily deals with helping ex-military personnel and veterans with readjustments in life when they retire. They also help in dealing with psychological traumas these veterans may have faced in the line of service."
export const DESCRIPTION_SOCIAL_WORK_PSYCHIATRIC_SOCIAL_WORKER = "Psychiatric social workers provide mental health services to individuals with high needs."
export const DESCRIPTION_SOCIAL_WORK_SOCIAL_POLICY_ANALYST = "Social policy analysts specialize in researching social issues and finding solutions to problems, such as hunger, crime, discrimination, poverty, disease, violence, and unemployment."
export const DESCRIPTION_SOCIAL_WORK_SA_SOCIAL_WORKER = "Someone who deals with addictions of people to various substances by helping them come out of the the vicious cycle through awareness programs, counselling etc."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_AIR_HOSTESS_STEWARD = "Someone who takes care of passengers and basic procedures in air travel including announcements, safety instructions, food distribution and coordination between the passengers and the pilots."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_BARTENDER = "Someone who works at a bar and uses his knowledge of various drinks and mixology to serve different drinks to customers."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_CHEF = "A Chef is someone who primarily works in the restaurant/hotel industry, leading the kitchen from food selection, creation and preparation."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_COMMERCIAL_PILOT = "They are in charge of inflight operations including the maneuvering of a passenger aircraft, routing to a destination and communication to the airport authorities."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_EVENT_MANAGER = "They  perform tasks such as finding and booking venues, liaising with clients and suppliers, handling logistics, managing budgets and invoicing, organizing accreditation, managing risk, and presenting post-event reports."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_FRONT_OFFICE_RECEPTIONIST = "A Receptionist serves visitors by greeting, welcoming, and directing them appropriately. Notifies company personnel of visitor arrival. Maintains security and telecommunications system. Informs visitors by answering or referring inquiries."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_HOUSEKEEPING = "They conduct basic tasks in a household such as cleaning, cooking, laundry, running errands and so on."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_OPERATIONS_MANAGER = "An Operations Manager takes care and oversees of all the day to day activities of an industry. In this case he/she takes care of the activities concerning hotels and restaurants."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_PLANNER = "A Travel Planner plans out all the details of a trip including routes, destinations, timeline, budget, accommodation aspects. The planning could be for vacations, getaways or travel plans for company executives."
export const DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_VLOGGER = "Someone who records his/her experience of travelling across various parts of the world primarily through video recordings."

export const CATEGORY_OBJECT_CIVIL_SERVICES = {
    "title": CATEGORY_TITLE_CIVIL_SERVICES,
    "description": DESCRIPTION_TITLE_CIVIL_SERVICES,
    "image": PATH_CIVIL_SERVICES_IAS
}
export const CATEGORY_OBJECT_COMMERCE = {
    "title": CATEGORY_TITLE_COMMERCE,
    "description": DESCRIPTION_TITLE_COMMERCE,
    "image": PATH_COMMERCE_CFA
}
export const CATEGORY_OBJECT_DEFENCE_POLICE_FORCES = {
    "title": CATEGORY_TITLE_DEFENCE_POLICE_FORCES,
    "description": DESCRIPTION_TITLE_DEFENCE_POLICE_FORCES,
    "image": PATH_DEFENCE_POLICE_FORCES_ARMY
}
export const CATEGORY_OBJECT_DESIGN_FINE_ARTS = {
    "title": CATEGORY_TITLE_DESIGN_FINE_ARTS,
    "description": DESCRIPTION_TITLE_DESIGN_FINE_ARTS,
    "image": PATH_DESIGN_FINE_ARTS_PAINTER
}
export const CATEGORY_OBJECT_EDUCATION = {
    "title": CATEGORY_TITLE_EDUCATION,
    "description": DESCRIPTION_TITLE_EDUCATION,
    "image": PATH_EDUCATION_TEACHER
}
export const CATEGORY_OBJECT_ENGINEER = {
    "title": CATEGORY_TITLE_ENGINEER,
    "description": DESCRIPTION_TITLE_ENGINEER,
    "image": PATH_ENGINEER_MECHANICAL
}
export const CATEGORY_OBJECT_HUMANITITES = {
    "title": CATEGORY_TITLE_HUMANITITES, "description": DESCRIPTION_TITLE_HUMANITITES,
    "image": PATH_HUMANITIES_POLITICAL_SCIENCES
}
export const CATEGORY_OBJECT_LAW = {
    "title": CATEGORY_TITLE_LAW, "description": DESCRIPTION_TITLE_LAW,
    "image": PATH_LAW_GENERAL_PRACTICE_LAWYER
}
export const CATEGORY_OBJECT_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS = {
    "title": CATEGORY_TITLE_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
    "description": DESCRIPTION_TITLE_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
    "image": PATH_MASS_MEDIA_JOURNALIST
}
export const CATEGORY_OBJECT_MEDICAL_SCIENCE = {
    "title": CATEGORY_TITLE_MEDICAL_SCIENCE,
    "description": DESCRIPTION_TITLE_MEDICAL_SCIENCE,
    "image": PATH_MEDICAL_SCIENCE_GENERAL_MEDICINE
}
export const CATEGORY_OBJECT_MISCELLANEOUS = {
    "title": CATEGORY_TITLE_MISCELLANEOUS,
    "description": DESCRIPTION_TITLE_MISCELLANEOUS,
    "image": PATH_MISCELLANEOUS_ATHLETE
}
export const CATEGORY_OBJECT_PURE_SCIENCES = {
    "title": CATEGORY_TITLE_PURE_SCIENCES,
    "description": DESCRIPTION_TITLE_PURE_SCIENCES,
    "image": PATH_PURE_SCIENCES_MATHEMATICIAN
}
export const CATEGORY_OBJECT_SOCIAL_WORK = {
    "title": CATEGORY_TITLE_SOCIAL_WORK,
    "description": DESCRIPTION_TITLE_SOCIAL_WORK,
    "image": PATH_SOCIAL_WORK_COMMUNITY_SOCIAL_WORKER
}
export const CATEGORY_OBJECT_TRAVEL_TOURISM_HOSPITILITY = {
    "title": CATEGORY_TITLE_TRAVEL_TOURISM_HOSPITILITY,
    "description": DESCRIPTION_TITLE_TRAVEL_TOURISM_HOSPITILITY,
    "image": PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_VLOGGER
}


export const TRAIT_ADAPTABILITY = "Adaptability"
export const TRAIT_COMMUNICATION = "Communication"
export const TRAIT_CREATIVITY = "Creativity"
export const TRAIT_CRITICAL_THINKING = "Critical Thinking"
export const TRAIT_DISCIPLINE = "Discipline"
export const TRAIT_EMPATHY = "Empathy"
export const TRAIT_FLEXIBILITY = "Flexibility"
export const TRAIT_GRIT = "Grit"
export const TRAIT_INNOVATION = "Innovation"
export const TRAIT_LEADERSHIP = "Leadership"
export const TRAIT_MANAGEMENT = "Management"
export const TRAIT_METICULOUS = "Meticulous"
export const TRAIT_PLANNING_ORGANIZATION = "Planning & Organization"
export const TRAIT_PROBLEM_SOLVING = "Problem Solving"
export const TRAIT_PROFESSIONALISM_ETIQUETTE = "Professionalism & Etiquette"

export const PATH_IMAGE_TRAIT_ADAPTABILITY = "traits/Adaptability .svg"
export const PATH_IMAGE_TRAIT_COMMUNICATION = "traits/Communication .svg"
export const PATH_IMAGE_TRAIT_CREATIVITY = "traits/Creativity .svg"
export const PATH_IMAGE_TRAIT_CRITICAL_THINKING = "traits/Critical Thinking.svg"
export const PATH_IMAGE_TRAIT_DISCIPLINE = "traits/Discipline .svg"
export const PATH_IMAGE_TRAIT_EMPATHY = "traits/Empathy .svg"
export const PATH_IMAGE_TRAIT_FLEXIBILITY = "traits/Flexibility .svg"
export const PATH_IMAGE_TRAIT_GRIT = "traits/Grit .svg"
export const PATH_IMAGE_TRAIT_INNOVATION = "traits/Innovation .svg"
export const PATH_IMAGE_TRAIT_LEADERSHIP = "traits/Leadership .svg"
export const PATH_IMAGE_TRAIT_MANAGEMENT = "traits/Management .svg"
export const PATH_IMAGE_TRAIT_METICULOUS = "traits/Meticulous.svg"
export const PATH_IMAGE_TRAIT_PLANNING_ORGANIZATION = "traits/Planning & Organization.svg"
export const PATH_IMAGE_TRAIT_PROBLEM_SOLVING = "traits/Problem Solving.svg"
export const PATH_IMAGE_TRAIT_PROFESSIONALISM_ETIQUETTE = "traits/Professionalism & Etiquette.svg"

export const DESCRIPTION_ADAPTABILITY = "You can cope very well with a change in situation, thinking of a new solution as per current setting."
export const DESCRIPTION_COMMUNICATION = "You have the ability to convey information or knowledge very effectively and efficiently."
export const DESCRIPTION_CREATIVITY = "You are imaginative and are always thinking of new ways to solve a problem or complete a task."
export const DESCRIPTION_CRITICAL_THINKING = "You can quickly analyze facts and information to form a judgement, guide your belief system, and take action."
export const DESCRIPTION_DISCIPLINE = "You always uphold the integrity of your organization by ensuring that you set and follow high standards of governance."
export const DESCRIPTION_EMPATHY = "You have the ability to understand and share the feelings of other people."
export const DESCRIPTION_FLEXIBILITY = "You are always very open to a changing and dynamic environment, and like to take up a challenge."
export const DESCRIPTION_GRIT = "You are extremely passionate about your goals and can display perseverance for a long term goal."
export const DESCRIPTION_INNOVATION = "You have the ability to imagine a new way to solve a problem and then implement it in reality."
export const DESCRIPTION_LEADERSHIP = "You have the ability to lead and guide a group of people towards a common goal."
export const DESCRIPTION_MANAGEMENT = "You have the ability to coordinate the efforts of people and help them reach a common goal."
export const DESCRIPTION_METICULOUS = "You are very detail oriented and careful in your work."
export const DESCRIPTION_PLANNING_ORGANIZATION = "You can quickly think about and list down the tasks that you need to do to achieve your goal."
export const DESCRIPTION_PROBLEM_SOLVING = "You can find solutions to difficult and complex problems you might even have never faced before."
export const DESCRIPTION_PROFESSIONALISM_ETIQUETTE = "Your presence and actions help in making other people comfortable around you."

export const OBJECT_TRAIT_ADAPTABILITY = {
    "title": TRAIT_ADAPTABILITY,
    "description": DESCRIPTION_ADAPTABILITY,
    "image": PATH_IMAGE_TRAIT_ADAPTABILITY
}
export const OBJECT_TRAIT_COMMUNICATION = {
    "title": TRAIT_COMMUNICATION,
    "description": DESCRIPTION_COMMUNICATION,
    "image": PATH_IMAGE_TRAIT_COMMUNICATION
}
export const OBJECT_TRAIT_CREATIVITY = {
    "title": TRAIT_CREATIVITY,
    "description": DESCRIPTION_CREATIVITY,
    "image": PATH_IMAGE_TRAIT_CREATIVITY
}
export const OBJECT_TRAIT_CRITICAL_THINKING = {
    "title": TRAIT_CRITICAL_THINKING,
    "description": DESCRIPTION_CRITICAL_THINKING,
    "image": PATH_IMAGE_TRAIT_CRITICAL_THINKING
}
export const OBJECT_TRAIT_DISCIPLINE = {
    "title": TRAIT_DISCIPLINE,
    "description": DESCRIPTION_DISCIPLINE,
    "image": PATH_IMAGE_TRAIT_DISCIPLINE
}
export const OBJECT_TRAIT_EMPATHY = {
    "title": TRAIT_EMPATHY,
    "description": DESCRIPTION_EMPATHY,
    "image": PATH_IMAGE_TRAIT_EMPATHY
}
export const OBJECT_TRAIT_FLEXIBILITY = {
    "title": TRAIT_FLEXIBILITY,
    "description": DESCRIPTION_FLEXIBILITY,
    "image": PATH_IMAGE_TRAIT_FLEXIBILITY
}
export const OBJECT_TRAIT_GRIT = {"title": TRAIT_GRIT, "description": DESCRIPTION_GRIT, "image": PATH_IMAGE_TRAIT_GRIT}
export const OBJECT_TRAIT_INNOVATION = {
    "title": TRAIT_INNOVATION,
    "description": DESCRIPTION_INNOVATION,
    "image": PATH_IMAGE_TRAIT_INNOVATION
}
export const OBJECT_TRAIT_LEADERSHIP = {
    "title": TRAIT_LEADERSHIP,
    "description": DESCRIPTION_LEADERSHIP,
    "image": PATH_IMAGE_TRAIT_LEADERSHIP
}
export const OBJECT_TRAIT_MANAGEMENT = {
    "title": TRAIT_MANAGEMENT,
    "description": DESCRIPTION_MANAGEMENT,
    "image": PATH_IMAGE_TRAIT_MANAGEMENT
}
export const OBJECT_TRAIT_METICULOUS = {
    "title": TRAIT_METICULOUS,
    "description": DESCRIPTION_METICULOUS,
    "image": PATH_IMAGE_TRAIT_METICULOUS
}
export const OBJECT_TRAIT_PLANNING_ORGANIZATION = {
    "title": TRAIT_PLANNING_ORGANIZATION,
    "description": DESCRIPTION_PLANNING_ORGANIZATION,
    "image": PATH_IMAGE_TRAIT_PLANNING_ORGANIZATION
}
export const OBJECT_TRAIT_PROBLEM_SOLVING = {
    "title": TRAIT_PROBLEM_SOLVING,
    "description": DESCRIPTION_PROBLEM_SOLVING,
    "image": PATH_IMAGE_TRAIT_PROBLEM_SOLVING
}
export const OBJECT_TRAIT_PROFESSIONALISM_ETIQUETTE = {
    "title": TRAIT_PROFESSIONALISM_ETIQUETTE,
    "description": DESCRIPTION_PROFESSIONALISM_ETIQUETTE,
    "image": PATH_IMAGE_TRAIT_PROFESSIONALISM_ETIQUETTE
}

const qBank = [
    {
        option_one_image: PATH_ENGINEER_COMPUTER_SCIENCES,
        option_one_title: 'Computer Science Engineer',
        option_one_description: DESCRIPTION_ENGINEER_COMPUTER_SCIENCES,
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_MEDICAL_SCIENCE_GENERAL_MEDICINE,
        option_two_title: 'General Medicine Doctor',
        option_two_description: DESCRIPTION_MEDICAL_SCIENCE_GENERAL_MEDICINE,
        option_two_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_GRAPHIC_DESIGNER,
        option_three_title: 'Graphic Designer',
        option_three_description: DESCRIPTION_DESIGN_FINE_ARTS_GRAPHIC_DESIGNER,
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_TEACHER,
        option_four_title: 'Teacher',
        option_four_description: DESCRIPTION_EDUCATION_TEACHER,
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_LAW_CORPORATE_LAWYER,
        option_one_title: 'Corporate Lawyer',
        option_one_description: DESCRIPTION_LAW_CORPORATE_LAWYER,
        option_one_category_id: CATEGORY_ID_LAW,
        option_two_image: PATH_CIVIL_SERVICES_IAS,
        option_two_title: 'IAS',
        option_two_description: DESCRIPTION_CIVIL_SERVICES_IAS,
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_SOCIAL_WORK_SA_SOCIAL_WORKER,
        option_three_title: 'Substance Abuse Social Worker',
        option_three_description: DESCRIPTION_SOCIAL_WORK_SA_SOCIAL_WORKER,
        option_three_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_four_image: PATH_TRAVEL_TOURISM_HOSPITALITY_CHEF,
        option_four_title: 'Chef',
        option_four_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_CHEF,
        option_four_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
    },
    {
        option_one_image: PATH_MASS_MEDIA_JOURNALIST,
        option_one_title: 'Journalist',
        option_one_description: DESCRIPTION_MASS_MEDIA_JOURNALIST,
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_COMMERCE_ACCOUNTANT,
        option_two_title: 'Accountant',
        option_two_description: DESCRIPTION_COMMERCE_ACCOUNTANT,
        option_two_category_id: CATEGORY_ID_COMMERCE,
        option_three_image: PATH_PURE_SCIENCES_BOTANIST,
        option_three_title: 'Botanist',
        option_three_description: DESCRIPTION_PURE_SCIENCES_BOTANIST,
        option_three_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_four_image: PATH_HUMANITIES_HISTORIAN,
        option_four_title: 'Historian',
        option_four_description: DESCRIPTION_HUMANITIES_HISTORIAN,
        option_four_category_id: CATEGORY_ID_HUMANITITES,
    },
    {
        option_one_image: PATH_DEFENCE_POLICE_FORCES_ARMY,
        option_one_title: 'Army Officer',
        option_one_description: DESCRIPTION_DEFENCE_POLICE_FORCES_ARMY,
        option_one_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_two_image: PATH_MISCELLANEOUS_MAKE_UP_ARTIST,
        option_two_title: 'Make-up Artist',
        option_two_description: DESCRIPTION_MISCELLANEOUS_MAKE_UP_ARTIST,
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_ENGINEER_ELECTRONICS,
        option_three_title: 'Electronics and Communication Engineer',
        option_three_description: DESCRIPTION_ENGINEER_ELECTRONICS,
        option_three_category_id: CATEGORY_ID_ENGINEER,
        option_four_image: PATH_MEDICAL_SCIENCE_NEUROLOGY,
        option_four_title: 'Neurologist',
        option_four_description: DESCRIPTION_MEDICAL_SCIENCE_NEUROLOGY,
        option_four_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
    },
    {
        option_one_image: PATH_DESIGN_FINE_ARTS_WEB_DESIGNER,
        option_one_title: 'Web Designer',
        option_one_description: DESCRIPTION_DESIGN_FINE_ARTS_WEB_DESIGNER,
        option_one_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_two_image: PATH_EDUCATION_PROFESSOR,
        option_two_title: 'Professor',
        option_two_description: DESCRIPTION_EDUCATION_PROFESSOR,
        option_two_category_id: CATEGORY_ID_EDUCATION,
        option_three_image: PATH_LAW_CRIMINAL_LAWYER,
        option_three_title: 'Criminal Lawyer',
        option_three_description: DESCRIPTION_LAW_CRIMINAL_LAWYER,
        option_three_category_id: CATEGORY_ID_LAW,
        option_four_image: PATH_CIVIL_SERVICES_IPS,
        option_four_title: 'IPS',
        option_four_description: DESCRIPTION_CIVIL_SERVICES_IPS,
        option_four_category_id: CATEGORY_ID_CIVIL_SERVICES,
    },
    {
        option_one_image: PATH_SOCIAL_WORK_COMMUNITY_SOCIAL_WORKER,
        option_one_title: 'Community Social Worker',
        option_one_description: DESCRIPTION_SOCIAL_WORK_COMMUNITY_SOCIAL_WORKER,
        option_one_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_FRONT_OFFICE_RECEPTIONIST,
        option_two_title: 'Receptionist',
        option_two_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_FRONT_OFFICE_RECEPTIONIST,
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_MASS_MEDIA_BLOGGER,
        option_three_title: 'Blogger',
        option_three_description: DESCRIPTION_MASS_MEDIA_BLOGGER,
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_ENTREPRENEUR,
        option_four_title: 'Entrepreneur',
        option_four_description: DESCRIPTION_COMMERCE_ENTREPRENEUR,
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_PURE_SCIENCES_ZOOLOGIST,
        option_one_title: 'Zoologist',
        option_one_description: DESCRIPTION_PURE_SCIENCES_ZOOLOGIST,
        option_one_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_two_image: PATH_HUMANITIES_GEOGRAPHY,
        option_two_title: 'Geographer',
        option_two_description: DESCRIPTION_HUMANITIES_GEOGRAPHY,
        option_two_category_id: CATEGORY_ID_HUMANITITES,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_NAVY,
        option_three_title: 'Navy Officer',
        option_three_description: DESCRIPTION_DEFENCE_POLICE_FORCES_NAVY,
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_MISCELLANEOUS_POLITICIAN,
        option_four_title: 'Politician',
        option_four_description: DESCRIPTION_MISCELLANEOUS_POLITICIAN,
        option_four_category_id: CATEGORY_ID_MISCELLANEOUS,
    },
    {
        option_one_image: PATH_ENGINEER_ELECTRICAL,
        option_one_title: 'Electrical Engnieer',
        option_one_description: DESCRIPTION_ENGINEER_ELECTRICAL,
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_MEDICAL_SCIENCE_DENTIST,
        option_two_title: 'Dentist',
        option_two_description: DESCRIPTION_MEDICAL_SCIENCE_DENTIST,
        option_two_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_UX_DESIGNER,
        option_three_title: 'UX Designer',
        option_three_description: DESCRIPTION_DESIGN_FINE_ARTS_UX_DESIGNER,
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_ADMINISTRATOR,
        option_four_title: 'School Administrator',
        option_four_description: DESCRIPTION_EDUCATION_ADMINISTRATOR,
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_MASS_MEDIA_RADIO_JOCKEY,
        option_one_title: 'Radio Jockey',
        option_one_description: DESCRIPTION_MASS_MEDIA_RADIO_JOCKEY,
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_COMMERCE_MARKETING_MANAGER,
        option_two_title: 'Marketing Manager',
        option_two_description: DESCRIPTION_COMMERCE_MARKETING_MANAGER,
        option_two_category_id: CATEGORY_ID_COMMERCE,
        option_three_image: PATH_PURE_SCIENCES_GEOLOGIST,
        option_three_title: 'Geologist',
        option_three_description: DESCRIPTION_PURE_SCIENCES_GEOLOGIST,
        option_three_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_four_image: PATH_HUMANITIES_ARCHEOLOGIST,
        option_four_title: 'Archeologist',
        option_four_description: DESCRIPTION_HUMANITIES_ARCHEOLOGIST,
        option_four_category_id: CATEGORY_ID_PURE_SCIENCES,
    },
    {
        option_one_image: PATH_LAW_CIVIL_LAWYER,
        option_one_title: 'Civil Lawyer',
        option_one_description: DESCRIPTION_LAW_CIVIL_LAWYER,
        option_one_category_id: CATEGORY_ID_LAW,
        option_two_image: PATH_CIVIL_SERVICES_IFS,
        option_two_title: 'IFS',
        option_two_description: DESCRIPTION_CIVIL_SERVICES_IFS,
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_SOCIAL_WORK_H_PC_SOCIAL_WORKER,
        option_three_title: 'Palliative Care',
        option_three_description: DESCRIPTION_SOCIAL_WORK_H_PC_SOCIAL_WORKER,
        option_three_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_four_image: PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_VLOGGER,
        option_four_title: 'Travel Blogger',
        option_four_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_VLOGGER,
        option_four_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
    },
    {
        option_one_image: PATH_DEFENCE_POLICE_FORCES_AIR_FORCE,
        option_one_title: 'Airforce Officer',
        option_one_description: DESCRIPTION_DEFENCE_POLICE_FORCES_AIR_FORCE,
        option_one_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_two_image: PATH_MISCELLANEOUS_PHOTOGRAPHY,
        option_two_title: 'Photographer',
        option_two_description: DESCRIPTION_MISCELLANEOUS_PHOTOGRAPHY,
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_ENGINEER_AEROSPACE,
        option_three_title: 'Aerospace Engineer',
        option_three_description: DESCRIPTION_ENGINEER_AEROSPACE,
        option_three_category_id: CATEGORY_ID_ENGINEER,
        option_four_image: PATH_MEDICAL_SCIENCE_AYURVEDIC,
        option_four_title: 'Ayurvedic',
        option_four_description: DESCRIPTION_MEDICAL_SCIENCE_AYURVEDIC,
        option_four_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
    },
    {
        option_one_image: PATH_DESIGN_FINE_ARTS_INTERIOR_DESIGNER,
        option_one_title: 'Interior Designer',
        option_one_description: DESCRIPTION_DESIGN_FINE_ARTS_INTERIOR_DESIGNER,
        option_one_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_two_image: PATH_EDUCATION_LIBRARIAN,
        option_two_title: 'Librarian',
        option_two_description: DESCRIPTION_EDUCATION_LIBRARIAN,
        option_two_category_id: CATEGORY_ID_EDUCATION,
        option_three_image: PATH_LAW_FAMILY_LAWYER,
        option_three_title: 'Family Lawyer',
        option_three_description: DESCRIPTION_LAW_FAMILY_LAWYER,
        option_three_category_id: CATEGORY_ID_LAW,
        option_four_image: PATH_CIVIL_SERVICES_IAAS,
        option_four_title: 'Indian Audits and Accounts Service (IAAS)',
        option_four_description: DESCRIPTION_CIVIL_SERVICES_IAAS,
        option_four_category_id: CATEGORY_ID_CIVIL_SERVICES,
    },
    {
        option_one_image: PATH_SOCIAL_WORK_MV_CARE_SOCIAL_WORKER,
        option_one_title: 'Military Veteran Social Worker',
        option_one_description: DESCRIPTION_SOCIAL_WORK_MV_CARE_SOCIAL_WORKER,
        option_one_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_AIR_HOSTESS_STEWARD,
        option_two_title: 'Air Hostess/Steward',
        option_two_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_AIR_HOSTESS_STEWARD,
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_MASS_MEDIA_ACTOR,
        option_three_title: 'Actor',
        option_three_description: DESCRIPTION_MASS_MEDIA_ACTOR,
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_IB_SB,
        option_four_title: 'Insurance and Banking/Stock Broker',
        option_four_description: DESCRIPTION_COMMERCE_IB_SB,
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_PURE_SCIENCES_PHYSICIST,
        option_one_title: 'Physicist',
        option_one_description: DESCRIPTION_PURE_SCIENCES_PHYSICIST,
        option_one_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_two_image: PATH_HUMANITIES_POLITICAL_SCIENCES,
        option_two_title: 'Political Science',
        option_two_description: DESCRIPTION_HUMANITIES_POLITICAL_SCIENCES,
        option_two_category_id: CATEGORY_ID_HUMANITITES,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_CRPF,
        option_three_title: 'CRPF',
        option_three_description: DESCRIPTION_DEFENCE_POLICE_FORCES_CRPF,
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_MISCELLANEOUS_SPORTS_PLAYER,
        option_four_title: 'Sports Player',
        option_four_description: DESCRIPTION_MISCELLANEOUS_SPORTS_PLAYER,
        option_four_category_id: CATEGORY_ID_MISCELLANEOUS,
    },
    {
        option_one_image: PATH_MISCELLANEOUS_ATHLETE,
        option_one_title: 'Athlete',
        option_one_description: DESCRIPTION_MISCELLANEOUS_ATHLETE,
        option_one_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_two_image: PATH_DEFENCE_POLICE_FORCES_CISF,
        option_two_title: 'CISF',
        option_two_description: DESCRIPTION_DEFENCE_POLICE_FORCES_CISF,
        option_two_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_three_image: PATH_HUMANITIES_SOCIOLOGIST,
        option_three_title: 'Sociologist',
        option_three_description: DESCRIPTION_HUMANITIES_SOCIOLOGIST,
        option_three_category_id: CATEGORY_ID_HUMANITITES,
        option_four_image: PATH_PURE_SCIENCES_CHEMIST,
        option_four_title: 'Chemist',
        option_four_description: DESCRIPTION_PURE_SCIENCES_CHEMIST,
        option_four_category_id: CATEGORY_ID_PURE_SCIENCES,
    },
    {
        option_one_image: PATH_COMMERCE_BANK_OFFICER,
        option_one_title: 'Bank Officer/ Manager',
        option_one_description: DESCRIPTION_COMMERCE_BANK_OFFICER,
        option_one_category_id: CATEGORY_ID_COMMERCE,
        option_two_image: PATH_MASS_MEDIA_DIRECTOR,
        option_two_title: 'Movie Director',
        option_two_description: DESCRIPTION_MASS_MEDIA_DIRECTOR,
        option_two_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_three_image: PATH_TRAVEL_TOURISM_HOSPITALITY_OPERATIONS_MANAGER,
        option_three_title: 'Hotel Operations Manager',
        option_three_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_OPERATIONS_MANAGER,
        option_three_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_four_image: PATH_SOCIAL_WORK_CFSS_SOCIAL_WORKER,
        option_four_title: 'School Social Worker',
        option_four_description: DESCRIPTION_SOCIAL_WORK_CFSS_SOCIAL_WORKER,
        option_four_category_id: CATEGORY_ID_SOCIAL_WORK,
    },
    {
        option_one_image: PATH_CIVIL_SERVICES_ICAS,
        option_one_title: 'ICAS',
        option_one_description: DESCRIPTION_CIVIL_SERVICES_ICAS,
        option_one_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_two_image: PATH_LAW_DATA_PROTECTION_LAWYER,
        option_two_title: 'Data Protection Lawyer',
        option_two_description: DESCRIPTION_LAW_DATA_PROTECTION_LAWYER,
        option_two_category_id: CATEGORY_ID_LAW,
        option_three_image: PATH_EDUCATION_TUTOR,
        option_three_title: 'TUTOR',
        option_three_description: DESCRIPTION_EDUCATION_TUTOR,
        option_three_category_id: CATEGORY_ID_EDUCATION,
        option_four_image: PATH_DESIGN_FINE_ARTS_FASHION_DESIGNER,
        option_four_title: 'Fashion Designer',
        option_four_description: DESCRIPTION_DESIGN_FINE_ARTS_FASHION_DESIGNER,
        option_four_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
    },
    {
        option_one_image: PATH_MEDICAL_SCIENCE_HOMEOPATHY,
        option_one_title: 'Homeopathy Doctor',
        option_one_description: DESCRIPTION_MEDICAL_SCIENCE_HOMEOPATHY,
        option_one_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_two_image: PATH_ENGINEER_MECHANICAL,
        option_two_title: 'Mechanical Engineer',
        option_two_description: DESCRIPTION_ENGINEER_MECHANICAL,
        option_two_category_id: CATEGORY_ID_ENGINEER,
        option_three_image: PATH_DESIGN_FINE_ARTS_AUTOMOBILE_DESIGNER,
        option_three_title: 'Automobile Designer',
        option_three_description: DESCRIPTION_DESIGN_FINE_ARTS_AUTOMOBILE_DESIGNER,
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_LAB_TECHNICIAN,
        option_four_title: 'Lab Technician',
        option_four_description: DESCRIPTION_EDUCATION_LAB_TECHNICIAN,
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_ENGINEER_CIVIL,
        option_one_title: 'Civil Engineer',
        option_one_description: DESCRIPTION_ENGINEER_CIVIL,
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_LAW_IPR_LAWYER,
        option_two_title: 'IPR Lawyer',
        option_two_description: DESCRIPTION_LAW_IPR_LAWYER,
        option_two_category_id: CATEGORY_ID_LAW,
        option_three_image: PATH_MEDICAL_SCIENCE_PHARMACIST,
        option_three_title: 'Pharmacist',
        option_three_description: DESCRIPTION_MEDICAL_SCIENCE_PHARMACIST,
        option_three_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_four_image: PATH_CIVIL_SERVICES_ICLS,
        option_four_title: 'Indian Corporate Law Service (ICLS)',
        option_four_description: DESCRIPTION_CIVIL_SERVICES_ICLS,
        option_four_category_id: CATEGORY_ID_CIVIL_SERVICES,
    },
    {
        option_one_image: PATH_SOCIAL_WORK_PSYCHIATRIC_SOCIAL_WORKER,
        option_one_title: 'Psychiatric Social Worker',
        option_one_description: DESCRIPTION_SOCIAL_WORK_PSYCHIATRIC_SOCIAL_WORKER,
        option_one_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_HOUSEKEEPING,
        option_two_title: 'Housekeeping',
        option_two_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_HOUSEKEEPING,
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_MASS_MEDIA_PRODUCER,
        option_three_title: 'Film Producer',
        option_three_description: DESCRIPTION_MASS_MEDIA_PRODUCER,
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_CFA,
        option_four_title: 'CFA',
        option_four_description: DESCRIPTION_COMMERCE_CFA,
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_PURE_SCIENCES_MATHEMATICIAN,
        option_one_title: 'Mathematician',
        option_one_description: DESCRIPTION_PURE_SCIENCES_MATHEMATICIAN,
        option_one_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_two_image: PATH_HUMANITIES_LITERATURE_EXPERT,
        option_two_title: 'Literature Expert',
        option_two_description: DESCRIPTION_HUMANITIES_LITERATURE_EXPERT,
        option_two_category_id: CATEGORY_ID_HUMANITITES,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_BSF,
        option_three_title: 'Border Security Force (BSF)',
        option_three_description: DESCRIPTION_DEFENCE_POLICE_FORCES_BSF,
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_MISCELLANEOUS_FOOD_TESTER,
        option_four_title: 'Food Tester',
        option_four_description: DESCRIPTION_MISCELLANEOUS_FOOD_TESTER,
        option_four_category_id: CATEGORY_ID_MISCELLANEOUS,
    },
    {
        option_one_image: PATH_ENGINEER_METALLURGY,
        option_one_title: 'Metallurgical Engineer',
        option_one_description: DESCRIPTION_ENGINEER_METALLURGY,
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_MEDICAL_SCIENCE_PARAMEDICAL,
        option_two_title: 'Paramedical Doctor',
        option_two_description: DESCRIPTION_MEDICAL_SCIENCE_PARAMEDICAL,
        option_two_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_PRODUCT_DESIGNER,
        option_three_title: 'Product Designer',
        option_three_description: DESCRIPTION_DESIGN_FINE_ARTS_PRODUCT_DESIGNER,
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_SPECIAL_EDUCATION,
        option_four_title: 'Special Education Teacher',
        option_four_description: DESCRIPTION_EDUCATION_SPECIAL_EDUCATION,
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_LAW_TAXATION_LAWYER,
        option_one_title: 'Tax Lawyer',
        option_one_description: DESCRIPTION_LAW_TAXATION_LAWYER,
        option_one_category_id: CATEGORY_ID_LAW,
        option_two_image: PATH_CIVIL_SERVICES_IIS,
        option_two_title: 'IIS',
        option_two_description: DESCRIPTION_CIVIL_SERVICES_IIS,
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_SOCIAL_WORK_HEALTHCARE_SOCIAL_WORKER,
        option_three_title: 'Healthcare',
        option_three_description: DESCRIPTION_SOCIAL_WORK_HEALTHCARE_SOCIAL_WORKER,
        option_three_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_four_image: PATH_TRAVEL_TOURISM_HOSPITALITY_BARTENDER,
        option_four_title: 'Bartender',
        option_four_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_BARTENDER,
        option_four_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
    },
    {
        option_one_image: PATH_MASS_MEDIA_CHOREOGRAPHER,
        option_one_title: 'Choreographer',
        option_one_description: DESCRIPTION_MASS_MEDIA_CHOREOGRAPHER,
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_DEFENCE_POLICE_FORCES_ITBP,
        option_two_title: 'Indo – Tibetan Border Police Force (ITBP)',
        option_two_description: DESCRIPTION_DEFENCE_POLICE_FORCES_ITBP,
        option_two_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_three_image: PATH_MISCELLANEOUS_FITNESS,
        option_three_title: 'Fitness Expert',
        option_three_description: DESCRIPTION_MISCELLANEOUS_FITNESS,
        option_three_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_four_image: PATH_ENGINEER_MINING,
        option_four_title: 'Mining Engineer',
        option_four_description: DESCRIPTION_ENGINEER_MINING,
        option_four_category_id: CATEGORY_ID_ENGINEER,
    },
    {
        option_one_image: PATH_COMMERCE_FOREIGN_TRADE,
        option_one_title: 'Insurance agent/ Foreign Trade',
        option_one_description: DESCRIPTION_COMMERCE_FOREIGN_TRADE,
        option_one_category_id: CATEGORY_ID_COMMERCE,
        option_two_image: PATH_PURE_SCIENCES_BIOCHEMIST,
        option_two_title: 'Biochemist',
        option_two_description: DESCRIPTION_PURE_SCIENCES_BIOCHEMIST,
        option_two_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_three_image: PATH_HUMANITIES_LANGUAGE_EXPERT,
        option_three_title: 'Language Expert',
        option_three_description: DESCRIPTION_HUMANITIES_LANGUAGE_EXPERT,
        option_three_category_id: CATEGORY_ID_HUMANITITES,
        option_four_image: PATH_LAW_GENERAL_PRACTICE_LAWYER,
        option_four_title: 'General Practice Lawyer',
        option_four_description: DESCRIPTION_LAW_GENERAL_PRACTICE_LAWYER,
        option_four_category_id: CATEGORY_ID_LAW,
    },
    {
        option_one_image: PATH_MEDICAL_SCIENCE_VETERINARY,
        option_one_title: 'Veterinary Doctor',
        option_one_description: DESCRIPTION_MEDICAL_SCIENCE_VETERINARY,
        option_one_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_two_image: PATH_SOCIAL_WORK_LS_SOCIAL_WORKER,
        option_two_title: 'Labor Support Social Worker',
        option_two_description: DESCRIPTION_SOCIAL_WORK_LS_SOCIAL_WORKER,
        option_two_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_three_image: PATH_MASS_MEDIA_SINGER,
        option_three_title: 'Singer',
        option_three_description: DESCRIPTION_MASS_MEDIA_SINGER,
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_ECONOMIST,
        option_four_title: 'Economist',
        option_four_description: DESCRIPTION_COMMERCE_ECONOMIST,
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_DESIGN_FINE_ARTS_ANIMATION_DESIGNER,
        option_one_title: 'Animation Designer',
        option_one_description: DESCRIPTION_DESIGN_FINE_ARTS_ANIMATION_DESIGNER,
        option_one_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_two_image: PATH_MISCELLANEOUS_TATTOO_ARTIST,
        option_two_title: 'Tattoo Artist',
        option_two_description: DESCRIPTION_MISCELLANEOUS_TATTOO_ARTIST,
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_ASSAM_RIFFLE,
        option_three_title: 'Assam Riffle Officer',
        option_three_description: DESCRIPTION_DEFENCE_POLICE_FORCES_ASSAM_RIFFLE,
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_HUMANITIES_PUBLIC_POLICY_MAKER,
        option_four_title: 'Public Policy Maker',
        option_four_description: DESCRIPTION_HUMANITIES_PUBLIC_POLICY_MAKER,
        option_four_category_id: CATEGORY_ID_HUMANITITES,
    },
    {
        option_one_image: PATH_EDUCATION_COUNSELLOR,
        option_one_title: 'Counsellor',
        option_one_description: DESCRIPTION_EDUCATION_COUNSELLOR,
        option_one_category_id: CATEGORY_ID_EDUCATION,
        option_two_image: PATH_CIVIL_SERVICES_IPOS,
        option_two_title: 'Indian Postal Service (IPoS)',
        option_two_description: DESCRIPTION_CIVIL_SERVICES_IPOS,
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_TRAVEL_TOURISM_HOSPITALITY_EVENT_MANAGER,
        option_three_title: 'Event Manager',
        option_three_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_EVENT_MANAGER,
        option_three_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_four_image: PATH_PURE_SCIENCES_AGRICULTURIST,
        option_four_title: 'Agriculturist',
        option_four_description: DESCRIPTION_PURE_SCIENCES_AGRICULTURIST,
        option_four_category_id: CATEGORY_ID_PURE_SCIENCES,
    },
    {
        option_one_image: PATH_ENGINEER_ARCHITECHTURE,
        option_one_title: 'Architect',
        option_one_description: DESCRIPTION_ENGINEER_ARCHITECHTURE,
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_COMMERCIAL_PILOT,
        option_two_title: 'Pilot',
        option_two_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_COMMERCIAL_PILOT,
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_CIVIL_SERVICES_IRAS,
        option_three_title: 'Indian Railway Accounts Service',
        option_three_description: DESCRIPTION_CIVIL_SERVICES_IRAS,
        option_three_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_four_image: PATH_SOCIAL_WORK_SOCIAL_POLICY_ANALYST,
        option_four_title: 'Social Policy Analyst',
        option_four_description: DESCRIPTION_SOCIAL_WORK_SOCIAL_POLICY_ANALYST,
        option_four_category_id: CATEGORY_ID_SOCIAL_WORK,
    },
    {
        option_one_image: PATH_MASS_MEDIA_SCRIPT_WRITER,
        option_one_title: 'Script Writer',
        option_one_description: DESCRIPTION_MASS_MEDIA_SCRIPT_WRITER,
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_COMMERCE_SALES_MANAGER,
        option_two_title: 'Sales Manager',
        option_two_description: DESCRIPTION_COMMERCE_SALES_MANAGER,
        option_two_category_id: CATEGORY_ID_COMMERCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_PAINTER,
        option_three_title: 'Painter',
        option_three_description: DESCRIPTION_DESIGN_FINE_ARTS_PAINTER,
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_MEDICAL_SCIENCE_PHYSIOTHERAPIST,
        option_four_title: 'Physiotherapist',
        option_four_description: DESCRIPTION_MEDICAL_SCIENCE_PHYSIOTHERAPIST,
        option_four_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
    },
    {
        option_one_image: PATH_EDUCATION_PSYCHOLOGIST,
        option_one_title: 'Psychologist',
        option_one_description: DESCRIPTION_EDUCATION_PSYCHOLOGIST,
        option_one_category_id: CATEGORY_ID_EDUCATION,
        option_two_image: PATH_LAW_IMMIGRATION_LAWYER,
        option_two_title: 'Immigration Lawyer',
        option_two_description: DESCRIPTION_LAW_IMMIGRATION_LAWYER,
        option_two_category_id: CATEGORY_ID_LAW,
        option_three_image: PATH_PURE_SCIENCES_OCEANOLOGIST,
        option_three_title: 'Oceanology',
        option_three_description: DESCRIPTION_PURE_SCIENCES_OCEANOLOGIST,
        option_three_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_four_image: PATH_HUMANITIES_PHILOSOPHER,
        option_four_title: 'Philosopher',
        option_four_description: DESCRIPTION_HUMANITIES_PHILOSOPHER,
        option_four_category_id: CATEGORY_ID_HUMANITITES,
    },
    {
        option_one_image: PATH_MISCELLANEOUS_HAIR_STYLIST,
        option_one_title: 'Hair Stylist',
        option_one_description: DESCRIPTION_MISCELLANEOUS_HAIR_STYLIST,
        option_one_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_two_image: PATH_MASS_MEDIA_COMEDIAN,
        option_two_title: 'Comedian',
        option_two_description: DESCRIPTION_MASS_MEDIA_COMEDIAN,
        option_two_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_three_image: PATH_CIVIL_SERVICES_IRS_IT,
        option_three_title: 'Indian Revenue Service (IRS)',
        option_three_description: DESCRIPTION_CIVIL_SERVICES_IRS_IT,
        option_three_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_four_image: PATH_EDUCATION_SPORTS_COACH,
        option_four_title: 'Sports Coach',
        option_four_description: DESCRIPTION_EDUCATION_SPORTS_COACH,
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_DEFENCE_POLICE_FORCES_SSB,
        option_one_title: 'SSB',
        option_one_description: DESCRIPTION_DEFENCE_POLICE_FORCES_SSB,
        option_one_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_two_image: PATH_MISCELLANEOUS_NUTRITIONIST,
        option_two_title: 'Nutritionist',
        option_two_description: DESCRIPTION_MISCELLANEOUS_NUTRITIONIST,
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_LAW_LABOUR_LAWYER,
        option_three_title: 'Labour Lawyer',
        option_three_description: DESCRIPTION_LAW_LABOUR_LAWYER,
        option_three_category_id: CATEGORY_ID_LAW,
        option_four_image: PATH_DESIGN_FINE_ARTS_SCULPTOR,
        option_four_title: 'Sculptor',
        option_four_description: DESCRIPTION_DESIGN_FINE_ARTS_SCULPTOR,
        option_four_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
    },
    {
        option_one_image: PATH_ENGINEER_BIOTECHNOLOGY,
        option_one_title: 'Biotechnology',
        option_one_description: DESCRIPTION_ENGINEER_BIOTECHNOLOGY,
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_SOCIAL_WORK_HSW_SOCIAL_WORKER,
        option_two_title: 'Hygiene Sanitation Social Worker',
        option_two_description: DESCRIPTION_SOCIAL_WORK_HSW_SOCIAL_WORKER,
        option_two_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_three_image: PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_PLANNER,
        option_three_title: 'Travel Planner',
        option_three_description: DESCRIPTION_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_PLANNER,
        option_three_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_four_image: PATH_COMMERCE_PORTFOLIO_MANAGER,
        option_four_title: 'Portfolio Manager',
        option_four_description: DESCRIPTION_COMMERCE_PORTFOLIO_MANAGER,
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_MEDICAL_SCIENCE_NURSE,
        option_one_title: 'Nurse',
        option_one_description: DESCRIPTION_MEDICAL_SCIENCE_NURSE,
        option_one_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_two_image: PATH_PURE_SCIENCES_ASTRONOMIST,
        option_two_title: 'Astronomist',
        option_two_description: DESCRIPTION_PURE_SCIENCES_ASTRONOMIST,
        option_two_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_three_image: PATH_HUMANITIES_HR_MANAGER,
        option_three_title: 'Human Resource Manager',
        option_three_description: DESCRIPTION_HUMANITIES_HR_MANAGER,
        option_three_category_id: CATEGORY_ID_HUMANITITES,
        option_four_image: PATH_DEFENCE_POLICE_FORCES_POLICE_FORCE,
        option_four_title: 'Police Force',
        option_four_description: DESCRIPTION_DEFENCE_POLICE_FORCES_POLICE_FORCE,
        option_four_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
    },
];

export default qBank
