import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import Defence from "../illustrations/result/Army.jpg"
import Miscellaneous from "../illustrations/result/Athlete.jpg"
import Commerce from "../illustrations/result/CFA.jpg"
import SocialWork from "../illustrations/result/Community Social Worker.jpg"
import Medicine from "../illustrations/result/General Medicine.jpg"
import Lawyer from "../illustrations/result/General Practise Lawyer.jpg"
import CivilServices from "../illustrations/result/IAS.jpg"
import MassMedia from "../illustrations/result/Journalist.jpg"
import PureSciences from "../illustrations/result/Mathematician.jpg"
import Engineering from "../illustrations/result/Mechanical.jpg"
import Arts from "../illustrations/result/Painter.jpg"
import Humanities from "../illustrations/result/Political Sciences.jpg"
import Education from "../illustrations/result/Teacher.jpg"
import TravelTourism from "../illustrations/result/Travel Vlogger.jpg"

import Adaptability from "../illustrations/traits/Adaptability .jpg"
import Communication from "../illustrations/traits/Communication .jpg"
import Creativity from "../illustrations/traits/Creativity .jpg"
import CriticalThinking from "../illustrations/traits/Critical Thinking.jpg"
import Discipline from "../illustrations/traits/Discipline .jpg"
import Empathy from "../illustrations/traits/Empathy .jpg"
import Flexibility from "../illustrations/traits/Flexibility .jpg"
import Grit from "../illustrations/traits/Grit .jpg"
import Innovation from "../illustrations/traits/Innovation .jpg"
import Leadership from "../illustrations/traits/Leadership .jpg"
import Management from "../illustrations/traits/Management .jpg"
import Meticulous from "../illustrations/traits/Meticulous.jpg"
import PlanningOrganization from "../illustrations/traits/Planning & Organization.jpg"
import ProblemSolving from "../illustrations/traits/Problem Solving.jpg"
import ProfessionalismEtiquette from "../illustrations/traits/Professionalism & Etiquette.jpg"


const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff"
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    categoryContainer: {
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "row",
        padding: 5,
        margin: 10
    },
    bioContainer: {
        backgroundColor: "#f6f6f5",
        color: "#25BEC2",
        border: "1px solid #4a4a4a",
        padding: 5,
        margin: 12,
        display: "flex",
        flexDirection: "column",
    },
    movieDetails: {
        display: "flex",
        marginLeft: 5
    },
    docTitle: {
        fontSize: 15,
        marginBottom: 10,
        fontColor: "#FFF"
    },
    headingTitle: {
        fontSize: 12,
        marginBottom: 10,
        fontColor: "#FFF"
    },
    headingDescription: {
        fontSize: 9,
        marginBottom: 10,
        fontColor: "#FFF"
    },
    subHeading: {
        fontSize: 12,
        fontColor: "#25BEC2",
        fontWeight: "bold"
    },
    bioHeading: {
        fontSize: 12,
        marginBottom: 10,
        fontColor: "#25BEC2",
        fontWeight: "bold"
    },
    movieOverview: {
        fontSize: 10
    },

    image: {
        height: 75,
        width: 100,
        margin: 10
    },
    subtitle: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: 150,
        alignItems: "center",
        marginBottom: 12
    },
    vote: {
        display: "flex",
        flexDirection: "row"
    },
    rating: {
        height: 10,
        width: 10
    },
    vote_text: {
        fontSize: 10
    },
    vote_pop: {
        fontSize: 10,
        padding: 2,
        backgroundColor: "#61C74F",
        color: "#fff"
    },
    vote_pop_text: {
        fontSize: 10,
        marginLeft: 4
    },
    overviewContainer: {
        minHeight: 110
    },
    detailsFooter: {
        display: "flex",
        flexDirection: "row",
        color: "#25BEC2",
        padding: "20px",
    },
    lang: {
        fontSize: 8,
        fontWeight: 700
    },
    vote_average: {
        fontSize: 8,
        marginLeft: 4,
        fontWeight: "bold"
    },

    title: {
        position: "relative",
        backgroundColor: "#25BEC2",
        color: "#FFF",
        padding: "20px",
        textAlign: "center",
        fontSize: 1.8,
        borderBottomLeftRadius: "25px",
        borderBottomRightRadius: "25px",
    },

    questionContainer: {
        position: "relative",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#25BEC2",
        color: "#FFF",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    }
});

var getCareerImage = function (category) {
    switch (category) {
        case "Civil Services":
            return CivilServices
        case "Commerce":
            return Commerce
        case "Defence & Police Forces":
            return Defence
        case "Design & Fine Arts":
            return Arts
        case "Education":
            return Education
        case "Engineer":
            return Engineering
        case "Humanities":
            return Humanities
        case "Law":
            return Lawyer
        case "Mass Media,Journalism, File Production & Performing Arts":
            return MassMedia
        case "Medical Science":
            return Medicine
        case "Miscellaneous":
            return Miscellaneous
        case "Pure Sciences":
            return PureSciences
        case "Social Work":
            return SocialWork
        case "Travel, Tourism & Hospitality":
            return TravelTourism
    }
}

var getTrait = function (trait) {
    switch (trait) {
        case "Adaptability":
            return Adaptability
        case "Communication":
            return Communication
        case "Creativity":
            return Creativity
        case "Critical Thinking":
            return CriticalThinking
        case "Discipline":
            return Discipline
        case "Empathy":
            return Empathy
        case "Flexibility":
            return Flexibility
        case "Grit":
            return Grit
        case "Innovation":
            return Innovation
        case "Leadership":
            return Leadership
        case "Management":
            return Management
        case "Meticulous":
            return Meticulous
        case "Planning & Organization":
            return PlanningOrganization
        case "Problem Solving":
            return ProblemSolving
        case "Professionalism & Etiquette":
            return ProfessionalismEtiquette
    }
}


export function PdfDocument(props) {
    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.title}>
                    <Text style={styles.docTitle}>PathFinder</Text>
                </View>

                <View style={styles.bioContainer}>
                    <Text style={styles.bioHeading}>{props.user_details.user_name}</Text>
                    <Text style={styles.bioHeading}>{props.user_details.user_school}</Text>
                    <Text style={styles.bioHeading}>{props.user_details.user_standard}</Text>
                </View>

                <View style={styles.detailsFooter}>
                    <Text style={styles.subHeading}>Your top four Professions are</Text>
                </View>

                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        src={getCareerImage(props.recommendation_one.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.recommendation_one.title}</Text>
                        <Text style={styles.headingDescription}>{props.recommendation_one.description}</Text>
                    </View>

                </View>
                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        src={getCareerImage(props.recommendation_two.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.recommendation_two.title}</Text>
                        <Text style={styles.headingDescription}>{props.recommendation_two.description}</Text>
                    </View>

                </View>
                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        source={getCareerImage(props.recommendation_three.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.recommendation_three.title}</Text>
                        <Text style={styles.headingDescription}>{props.recommendation_three.description}</Text>
                    </View>

                </View>
                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        source={getCareerImage(props.recommendation_four.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.recommendation_four.title}</Text>
                        <Text style={styles.headingDescription}>{props.recommendation_four.description}</Text>
                    </View>

                </View>

                <View wrap={false} break style={styles.detailsFooter}>
                    <Text style={styles.subHeading}>Your Personality Traits are</Text>
                </View>

                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        source={getTrait(props.trait_one.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.trait_one.title}</Text>
                        <Text style={styles.headingDescription}>{props.trait_one.description}</Text>
                    </View>

                </View>
                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        source={getTrait(props.trait_two.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.trait_two.title}</Text>
                        <Text style={styles.headingDescription}>{props.trait_two.description}</Text>
                    </View>

                </View>
                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        source={getTrait(props.trait_three.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.trait_three.title}</Text>
                        <Text style={styles.headingDescription}>{props.trait_three.description}</Text>
                    </View>

                </View>
                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        source={getTrait(props.trait_four.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.trait_four.title}</Text>
                        <Text style={styles.headingDescription}>{props.trait_four.description}</Text>
                    </View>

                </View>
                <View wrap={false} style={styles.categoryContainer}>

                    <Image
                        style={styles.image}
                        source={getTrait(props.trait_five.title)}
                    />

                    <View style={styles.questionContainer}>
                        <Text style={styles.headingTitle}>{props.trait_five.title}</Text>
                        <Text style={styles.headingDescription}>{props.trait_five.description}</Text>
                    </View>

                </View>


                {/*{props.data*/}
                {/*    ? props.data.map((a, index) => {*/}
                {/*        return (*/}
                {/*            <View key={index} style={styles.movieContainer}>*/}
                {/*                <Image*/}
                {/*                    style={styles.image}*/}
                {/*                    source={*/}
                {/*                        a.poster_path !== null*/}
                {/*                            ? `{POSTER_PATH}{a.poster_path}`*/}
                {/*                            : "150.jpg"*/}
                {/*                    }*/}
                {/*                />*/}
                {/*                <View style={styles.movieDetails}>*/}
                {/*                    <Text style={styles.movieTitle}>{a.title}</Text>*/}
                {/*                    <View style={styles.subtitle}>*/}
                {/*                        <View style={styles.vote}>*/}
                {/*                            <Image source="star.png" style={styles.rating}/>*/}
                {/*                            <Text style={styles.vote_text}>{a.vote_count}</Text>*/}
                {/*                        </View>*/}
                {/*                        <View style={styles.vote}>*/}
                {/*                            <Text style={styles.vote_pop}>{a.popularity}</Text>*/}
                {/*                            <Text style={styles.vote_pop_text}>Popularity</Text>*/}
                {/*                        </View>*/}
                {/*                    </View>*/}
                {/*                    <View style={styles.overviewContainer}>*/}
                {/*                        <Text style={styles.movieOverview}>{a.overview}</Text>*/}
                {/*                    </View>*/}
                {/*                    <View style={styles.detailsFooter}>*/}
                {/*                        <Text style={styles.lang}>*/}
                {/*                            Language: {a.original_language.toUpperCase()}*/}
                {/*                        </Text>*/}
                {/*                        <Text style={styles.vote_average}>*/}
                {/*                            Average Votes: {a.vote_average}*/}
                {/*                        </Text>*/}
                {/*                        <Text style={styles.vote_average}>*/}
                {/*                            Release Date:{" "}*/}
                {/*                            {moment(a.release_date, "YYYY-MM-DD").format(*/}
                {/*                                " MMMM D Y"*/}
                {/*                            )}*/}
                {/*                        </Text>*/}
                {/*                    </View>*/}
                {/*                </View>*/}
                {/*            </View>*/}
                {/*        );*/}
                {/*    })*/}
                {/*    : ""}*/}
            </Page>
        </Document>
    )
        ;
}
